<template>
  <base-form
      :loading="loading"
      :show-cancel="showCancel"
      :can-create-another-entity="!model.id"
      :save-text="$t('Create craft code')"
      :update-text="$t('Update craft code')"
      layout="modal"
      @cancel="$emit('cancel')"
      @submit="onSubmit"
  >
    <div class="col-span-6 md:col-span-2">
      <base-input v-model="model.code"
                  :label="$t('Code')"
                  :placeholder="$t('Code')"
                  id="code"
                  rules="required|max:8|noWildcard"
      />
    </div>
    <div class="col-span-6 md:col-span-1">
      <base-input v-model="model.level"
                  :label="$t('Level')"
                  :placeholder="$t('Level')"
                  id="level"
                  rules="required|length:1|noWildcard"
      />
    </div>
    <div class="col-span-6 md:col-span-2">
      <base-input v-model="model.description"
                  :label="$t('Description')"
                  :placeholder="$t('Description')"
                  id="description"
                  rules="required|max:150"
                  @blur="onBlurDescriptionInput"
      />
    </div>
    <div class="col-span-6 md:col-span-1">
      <ActiveSwitch v-model="model.active"></ActiveSwitch>
    </div>
    <div class="col-span-6 md:col-span-2">
      <base-input v-model="model.billing_description"
                  :label="$t('Billing Description')"
                  :placeholder="$t('Billing Description')"
                  id="billing_description"
                  rules="max:150"
      />
    </div>
    <div class="col-span-6 md:col-span-2">
      <base-input v-model="model.trade"
                  :label="$t('Trade')"
                  :placeholder="$t('Trade')"
                  id="trade"
                  rules="max:150"
      />
    </div>
    <div class="col-span-6 md:col-span-1">
      <base-input v-model="model.classification"
                  :label="$t('Classification')"
                  :placeholder="$t('Classification')"
                  id="classification"
                  rules="max:150"
      />
    </div>
    <div class="col-span-6 md:col-span-1">
      <base-input v-model="model.soc_code"
                  :label="$t('SOC Code')"
                  :placeholder="$t('SOC Code')"
                  id="soc_code"
                  rules="max:150"
      />
    </div>
    <div class="col-span-6 mb-4">
      <BillingRatesList :entity="data"
                        :source-type="billingRateSources.CraftCode"
                        ref="billingRates"
      />
    </div>
    <div class="col-span-6 mb-4">
      <SubTradesList
        v-if="data?.id"
        :craft_code_id="data?.id"
      />
    </div>
    <div class="col-span-6 md:col-span-1">
      <base-switch v-model="model.is_apprentice"
                   :label-info="$t('Apprentice')"
                   id="is_apprentice"
      />
    </div>
    <template v-if="model.is_apprentice">
      <div class="col-span-6 md:col-span-1">
        <base-input
            v-model="model.apprentice.id"
            :label="$t('Apprentice ID')"
            :placeholder="$t('Apprentice ID')"
            rules="max:150"
        />
      </div>
      <div class="col-span-6 md:col-span-1">
        <state-select
            :label="$t('Apprentice State')"
            v-model="model.apprentice.state"/>
      </div>
      <div class="col-span-6">
      </div>
      <div class="col-span-6 md:col-span-3">
        <base-input
            v-model="model.apprentice.program_name"
            :label="$t('Program Name')"
            :placeholder="$t('Program Name')"
            rules="max:300"
        />
      </div>
      <div class="col-span-6 md:col-span-3">
        <base-input
            v-model="model.apprentice.occupation_name"
            :label="$t('Occupation Name')"
            :placeholder="$t('Occupation Name')"
            rules="max:300"
        />
      </div>
      <div class="col-span-6 md:col-span-2">
        <base-input
            v-model="model.apprentice.step_name"
            :label="$t('Step Name')"
            :placeholder="$t('Step Name')"
            rules="max:150"
        />
      </div>
      <div class="col-span-6 md:col-span-1">
        <base-input
            v-model="model.apprentice.step_begin_hours"
            type="number"
            :label="$t('Step Begin Hours')"
            :placeholder="$t('Step Begin Hours')"
            :min="0"
        />
      </div>
      <div class="col-span-6 md:col-span-1">
        <base-input
            v-model="model.apprentice.step_end_hours"
            type="number"
            :label="$t('Step End Hours')"
            :placeholder="$t('Step End Hours')"
            :min="0"
        />
      </div>
    </template>
  </base-form>
</template>
<script>
  import axios from 'axios'
  import { billingRateSources } from '@/enum/enums'
  import BillingRatesList from '@/modules/common/components/BillingRatesList'
  import SubTradesList from "@/modules/payroll/components/codes/SubTradesList.vue";

  export default {
    inheritAttrs: false,
    components: {
      SubTradesList,
      BillingRatesList,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        loading: false,
        billingRateSources,
        showCancel: true,
        model: {
          code: '',
          level: '',
          description: '',
          billing_description: '',
          trade: '',
          soc_code: '',
          classification: '',
          is_apprentice: false,
          active: true,
          apprentice: {
            id: '',
            state: '',
            program_name: '',
            occupation_name: '',
            step_name: '',
            step_begin_hours: null,
            step_end_hours: null
          }
        },
      }
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/craft-codes/${this.model.id}`, this.model)
            this.$success(this.$t('Craft code updated.'))
            await this.runBillingRateOperations(this.model.id)
            this.$emit('save', this.model)
          } else {
            const { data } = await axios.post('/restify/craft-codes', this.model)
            await this.runBillingRateOperations(data.id)
            this.$success(this.$t('Craft code created.'))
            this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', data)
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          console.log('err', err)
          this.$error(this.$t('Could not update the craft code.'))
        } finally {
          this.loading = false
        }
      },
      onBlurDescriptionInput() {
        this.model.billing_description = this.model.description
      },
      async runBillingRateOperations(source_id) {
        const { billingRates } = this.$refs
        if (!billingRates) {
          return
        }
        await billingRates.runEntitiesOperation(source_id, billingRateSources.CraftCode)
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }
          const newData = {
            ...value.attributes
          }
          const apprentice = value.attributes.apprentice
          if (Array.isArray(apprentice) && apprentice?.length === 0 || !apprentice) {
            delete newData.apprentice
          }
          this.model = {
            ...this.model,
            ...newData,
          }
        },
      },
    },
  }
</script>
