<template>
  <div>
    <BaseForm
      :loading="loading"
      :show-back="true"
      :show-cancel="true"
      :save-text="$t('Create recurring LMP billing')"
      :update-text="$t('Update recurring LMP billing')"
      :submit-disabled="!!existingBillingId || gridContext.loading"
      :can-create-another-entity="!model.id"
      :sticky-header="expandedSections.length === 0"
      submit-button-type="button"
      layout="vertical"
      grid-classes="grid grid-cols-1"
      ref="baseForm"
      @cancel="onCancel"
      @submit="onSubmit"
    >
      <template #header>
        <ElCollapse
          v-model="expandedSections"
          class="col-span-1"
        >
          <BaseCollapseSection
            name="form-header"
            class="-m-6 mb-6"
            has-background
          >
            <template #title>
              <div class="flex flex-1 items-center justify-between form-header-summary">
                {{ $t('Recurring Lump Sum Billing') }}
                <portal-target
                  name="lmp-summary"
                />
              </div>
            </template>
            <div class="grid grid-cols-8 gap-x-4 py-5 px-6">
              <div class="col-span-6 grid grid-cols-6 gap-x-4">
                <CustomerSelect
                  v-model="model.customer_id"
                  :add-entity="true"
                  :initial-value="selectedCustomer"
                  :disabled="!!$route.params.id"
                  class="col-span-6 md:col-span-2"
                  rules="required"
                  @entity-change="onCustomerChanged"
                />

                <BaseInput
                  v-model="model.meta.architect"
                  :label="$t('Architect Project Number')"
                  :placeholder="$t('Architect Project Number')"
                  :name="$t('Architect Project Number')"
                  id="architect"
                  rules="max:12"
                  class="col-span-8 md:col-span-2"
                />

                <BaseInput
                  v-model="model.gross_amount"
                  :label="$t('Gross Billing')"
                  :placeholder="$t('Gross Billing')"
                  :min="-9999999999.99"
                  :step="1"
                  :max="9999999999.99"
                  id="gross_amount"
                  type="number"
                  format="price"
                  rules="required|grossAmount"
                  class="col-span-6 md:col-span-2"
                  @change="onChangeGrossBilling"
                />

                <BaseTextarea
                  v-model="model.description"
                  :label="$t('Description')"
                  :placeholder="$t('Description')"
                  :rows="2"
                  id="description"
                  rules="max:500"
                  class="col-span-6 md:col-span-4"
                />

                <div class="col-span-6 md:col-span-1" />

                <!-- Invoice Configuration -->
                <div class="col-span-8">
                  <h5 class="form-section-title">
                    {{ $t('Invoice Configuration') }}
                  </h5>
                  <div class="grid grid-cols-8 gap-x-3">
                    <div class="col-span-8 md:col-span-4">
                      <BillingTax
                        :model="model"
                        :data="data"
                        input-class="col-span-8 md:col-span-4"
                      />
                    </div>

                    <BaseSwitch
                      v-model="model.should_post"
                      :label-info="$t('Post Automatically')"
                      :placeholder="$t('Post Automatically')"
                      id="should_post"
                      class="col-span-8 md:col-span-2"
                    />
                    <!-- Hidden for now, we don't support recurring prepaid yet
                    <BaseSwitch
                      v-model="model.is_prepaid"
                      :label-info="$t('Is Prepaid')"
                      :placeholder="$t('Is Prepaid')"
                      id="is_prepaid"
                      class="col-span-8 md:col-span-2"
                    /> 
                    <template v-if="model.is_prepaid">
                      <BankSelect
                        v-model="model.bank_id"
                        :name="$t('Bank')"
                        :label="$t('Bank')"
                        rules="required"
                        class="col-span-8 md:col-span-2"
                      />
                      <BaseAlert
                        class="col-span-8"
                      >
                        {{ $t('Check Date and Check Number will be generated automatically based on invoice date and number.') }}
                      </BaseAlert>
                    </template>
                    -->
                  </div>
                </div>
              </div>
              <div class="col-span-2">
                <CustomerPreview
                  :key="customerPreviewRenderKey"
                  :entity="selectedCustomer.attributes"
                />
              </div>
              <div class="col-span-full grid grid-cols-6 gap-x-4">
                <!-- Schedule + Terms -->
                <div class="col-span-6 lg:col-span-3">
                  <BaseCollapseSection
                    key="schedule"
                    name="schedule"
                  >
                    <template #title>
                      <div class="flex items-end space-x-2">
                        <h5 class="form-section-title">
                          {{ $t('Schedule') }}
                        </h5>
                        <div
                          v-if="!expandedSections.includes('schedule') && recurrenceRuleFormatted"
                          class="text-sm font-normal text-gray-500"
                        >
                           ({{  recurrenceRuleFormatted }})
                        </div>
                      </div>
                    </template>
                    <RecurringScheduleInputs
                      v-model="model.recurring_rule"
                      :start-date.sync="model.start_date"
                      :invoice="data"
                      :entity="$t('Billing')"
                      @recurrence-rule-formatted="recurrenceRuleFormatted = $event"
                    />
                    <h5 class="form-section-title">
                      {{ $t('Terms (Due Date, Discount Date)') }}
                    </h5>
                    <div class="grid grid-cols-8 gap-4">
                      <BaseInput
                        v-model="model.due_date_days_after"
                        :label="$t('Due Date Days After Billing Date')"
                        :placeholder="$t('Due Date Days After Billing Date')"
                        :step="1"
                        :min="0"
                        type="number"
                        id="due_date_days_after"
                        class="col-span-8 md:col-span-3"
                      />
                      <BaseInput
                        v-model="model.discount_date_days_after"
                        :label="$t('Discount Date Days After Billing Date')"
                        :placeholder="$t('Discount Date Days After Billing Date')"
                        :step="1"
                        :min="0"
                        id="discount_date_days_after"
                        type="number"
                        rules="max_value:30|min_value:0"
                        class="col-span-8 md:col-span-3"
                      />
                    </div>
                  </BaseCollapseSection>
                </div>
                <!-- Email Options -->
                <div class="col-span-6 lg:col-span-3">
                  <RecurringReceivableSendEmails
                    v-model="model"
                    :billing="data"
                    :billingType="billingTypes.LumpSum"
                    :entity="StimulsoftPrintEntities.LumpSumBilling"
                    @update:sendAutomatically="onSendAutomaticallyChanged"
                  />
                </div>
              </div>
            </div>
          </BaseCollapseSection>
        </ElCollapse>
      </template>
      <LumpSumBillingEntriesV2
        ref="entriesTable"
        resource-endpoint="/restify/recurring-billing-entries"
        parentEntityField="recurring_billing_id"
        :billing="model"
        :customer="selectedCustomer.attributes"
        :computed-gross-billing="computedGrossBilling"
        :gross-amount.sync="model.gross_amount"
        @on-collapse-form-header="collapseFormHeader"
      />
    </BaseForm>
  </div>
</template>
<script>
import CustomerPreview from '@/modules/common/components/entity-preview/CustomerPreview.vue'
import LumpSumBillingEntriesV2 from '@/modules/accounts-receivable/components/lump-sum-billings/LumpSumBillingEntriesV2.vue'
import BillingDates from "@/modules/accounts-receivable/components/billings/BillingDates.vue";
import BillingTax from "@/modules/accounts-receivable/components/billings/BillingTax.vue";
import RecurringScheduleInputs from "@/modules/accounts-payable/components/recurring-invoice/RecurringScheduleInputs.vue";
import BillingPrintOptions from '@/modules/accounts-receivable/components/billings/BillingPrintOptions.vue';
import RecurringReceivableSendEmails from '@/modules/common/components/recurring/RecurringReceivableSendEmails.vue'

import axios from 'axios'
import { resourceStatuses } from '@/enum/enums'
import { billingTypeAbbr, billingTypes } from '@/modules/accounts-receivable/pages/billings/billings'
import { validateAgDataTable } from '@/components/ag-grid/tableUtils'
import { RestifyResources } from "@/components/form/util";
import { gridContext } from "@/components/ag-grid/gridContext";
import { StimulsoftPrintEntities } from '@/enum/stimulsoft';
import { ApiDateFormat } from "@/plugins/dateFormatPlugin";
import { getTimezone } from "@/modules/payroll/utils/timeCardUtils";

const init_sending_options = {
  sender_email: '',
  template_id: null,
  flags: {
    print_line_items: true,
    print_retention: true,
    print_sales_tax: true,
  },
  options: {
    include_attachments: false,
    include_pdf: true,
    send_a_copy: false,
    bcc_email: '',
  }
}

export default {
  components: {
    BillingTax,
    BillingDates,
    CustomerPreview,
    LumpSumBillingEntriesV2,
    RecurringScheduleInputs,
    BillingPrintOptions,
    RecurringReceivableSendEmails,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      billingTypes,
      StimulsoftPrintEntities,
      computedGrossBilling: true,
      existingBillingId: false,
      expandedSections: [],
      loading: false,
      customerPreviewRenderKey: 1,
      selectedCustomer: {},
      model: {
        customer_id: '',
        description: '',
        date: this.$now,
        gross_amount: 0,
        discount_percent: 0,
        type: billingTypeAbbr.LumpSum,
        bank_id: null,
        retention_percent: 0,
        sales_tax_amount: 0,
        open_amount: 0,
        meta: {
          architect: '',
          district_id: null,
          exempt_from_sales_tax: false,
        },
        // recurring stuff
        recurring_rule: '',
        start_date: new Date(),
        due_date_days_after: 0,
        discount_date_days_after: 0,
        issued_days_before: 0,
        should_post: true,
        is_prepaid: false,
        timezone: getTimezone(),
        // email
        send_automatically: true,
        recipients: [],
        sending_options: {
          ...init_sending_options,
        }
      },
      gridContext,
      createdId: null,
      recurrenceRuleFormatted: '',
    }
  },
  methods: {
    onChangeGrossBilling() {
      this.computedGrossBilling = false
    },
    onSendAutomaticallyChanged(value) {
      if (value && !this.expandedSections.includes('email-options')) {
        this.expandedSections.push('email-options')
      }
      else if (!value && this.expandedSections.includes('email-options')) {
        this.expandedSections = this.expandedSections.filter(section => section !== 'email-options')
      }
    },
    async onCustomerChanged(customer) {
      if (!customer) {
        customer = {
          attributes: {},
          id: '',
        }
      }
      this.model.recipients = []
      this.selectedCustomer = customer
      await this.$router.replace({
        path: this.$route.path,
        query: {
          customer_id: customer?.id,
        },
      })
      this.customerPreviewRenderKey++
    },
    async onSubmit() {
      try {
        const isInvalidData = await validateAgDataTable()
        if (isInvalidData) {
          return
        }

        if (this.existingBillingId) {
          return
        }

        this.loading = true

        const payload = {
          ...this.model,
          start_date: this.$formatDateOnly(new Date(this.model.start_date), ApiDateFormat),
        }

        delete payload.tags
        delete payload.billing_rate_type_id
        delete payload.bank_id // TODO: remove this line when prepaid is supported

        if (this.model.id) {
          await axios.put(`/restify/recurring-billings/${this.model.id}`, payload)
          await this.$refs.entriesTable.storeProgress(this.model.id)

          this.$success(this.$t('Recurring LMP billing updated successfully'))
          // await this.$addSystemGeneratedNote({
          //   resourceName: RestifyResources.RecurringBillings,
          //   resourceId: this.model.id,
          //   isEdit: true
          // })
        } else {

          const { data } = await axios.post('/restify/recurring-billings', payload)
          this.createdId = data.id
          await this.$refs.entriesTable.storeProgress(data.id)

          this.$success(this.$t('Recurring LMP billing created successfully'))
          // await this.$addSystemGeneratedNote({
          //   resourceName: RestifyResources.RecurringBillings,
          //   resourceId: data.id,
          // })
          if (this.$createAnotherEntity) {
            return this.$emit('create-another')
          }
          this.$router.push('/accounts-receivable/recurring-billings/lump-sum/pending')
        }

      } catch (err) {
        console.warn(err)
        if (this.createdId) {
          await axios.delete(`/restify/recurring-billings/${this.createdId}`)
          this.createdId = null
        }
        if (err?.handled) {
          return
        }
        this.$error(this.$t('Could not store recurring LMP billing'))
      } finally {
        this.loading = false
      }
    },
    onCancel() {
      return this.$router.push('/accounts-receivable/recurring-billings/lump-sum')
    },
    async collapseFormHeader() {
      if (!this.expandedSections.length) {
        return
      }

      const isValidForm = await this.$refs.baseForm?.validate()

      if (!isValidForm) {
        return
      }

      this.expandedSections = []
    },
    expandFormHeader() {
      this.expandedSections = ['form-header', 'schedule', 'email-options']
    },
    async initCreateModel() {
      this.expandFormHeader()
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        if (!value?.id) {
          this.initCreateModel()
          return
        }

        this.computedGrossBilling = false
        this.collapseFormHeader()

        this.model = {
          ...this.model,
          ...value.attributes,
        }

        if (!this.model.sending_options || Array.isArray(this.model.sending_options)) {
          this.model.sending_options = {
            ...init_sending_options,
          }
        }

        this.selectedCustomer = this.get(value, 'relationships.customer', {})
      },
    },
  },
}
</script>
