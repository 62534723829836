<template>
  <div>
    <AgDataTable
      :url="url"
      :columns="columns"
      :url-params="allUrlParams"
      :actions="'search,refresh'"
      :add-entity-in-new-tab="addEntityInNewTab"
      :searchPlaceholder="$t('Search recurring billings...')"
      :add-text="addText"
      :add-entity-url-query="addEntityUrlQuery"
      :title="title"
      :hide-actions="isReview ? 'add,edit,view,delete' : 'view,edit'"
      :selected-rows.sync="selectedRows"
      :actions-column-width="170"
      permission="recurring_billings"
      ref="table"
      @data-fetch="onTableDataFetch"
    >
      <template #attributes.recurring_rule="{row}">
        <RRuleText
          :value="row.attributes.recurring_rule"
          :invoice="row"
        />
      </template>
      <template #relationships.customer="{row}">
        <CustomerLink
          :data="row.relationships.customer"
          :showName="true"
        />
      </template>
      <template #frequency="{row}">
        {{ getFrequencyLabelFromPayable(row) }}
      </template>
      <template #start_date="{row}">
        {{ $formatDateOnly(row.attributes.start_date) }}
      </template>
      <template #attributes.cost_center="{row}">
        <div class="flex items-center space-x-1">
          <span>{{ row.attributes.cost_center }}</span>
          <template v-if="row.attributes.source_id">
            <WorkOrderLink
              v-if="row.attributes.source_type === 'work-order'"
              :data="row.relationships.workOrder"
            />
            <SourceLink
              v-else
              :data="row.attributes"
              :data-as-source="true"
            />
          </template>
        </div>
      </template>
      <template #extra-actions-before="{row}">
        <TableViewButton
          v-if="!isReview"
          :link="getViewPath(row)"
        />
        <TableEditButton
          v-if="!isReview"
          :link="getEditPath(row)"
          :openEntityInNewTab="true"
        />
        <BaseButton
          v-if="showActivateButton && !isReview"
          :loading="activeLoading"
          variant="gray-icon"
          size="xs"
          @click="activateInvoice(row)"
        >
          <PlayIcon class="w-4 h-4"/>
        </BaseButton>
      </template>
      <template #extra-actions="{row, index}">
        <BaseButton
          v-if="isReview"
          variant="gray-light"
          :title="$t('Review')"
          size="icon"
          class="table-action-button"
          @click="setCurrentEntity(row)"
        >
          <CheckCircleIcon class="w-4 h-4"/>
        </BaseButton>
      </template>
      <template #additional-actions>
        <BaseButton
          v-if="showActivateButton"
          :disabled="selectedRows.length === 0"
          :loading="activeLoading"
          variant="gray-link"
          @click="activateInvoices"
        >
          <PlayIcon class="w-4 h-4 mr-1"/>
          <span>{{ $t('Activate') }}</span>
        </BaseButton>
      </template>
      <template
        v-if="showAdvanceInvoicesAction"
        #dropdown-actions>
        <TableActionItem>
          <button
            class="action-item-text"
            :class="{
              'opacity-50': selectedRows.length === 0,
            }"
            type="button"
            :disabled="selectedRows.length === 0"
            @click="showGenerateBillingsDialog = true"
          >
            <RecurringInvoiceIcon class="w-4 h-4 mr-1"/>
            <span>{{ $t('Generate Invoices') }}</span>
          </button>
        </TableActionItem>
      </template>
    </AgDataTable>
    <GenerateBillingsDialog
      v-if="showGenerateBillingsDialog"
      :open.sync="showGenerateBillingsDialog"
      :recurring-billings="selectedRows"
      @save="onBillingsGenerated"
      @close="showGenerateBillingsDialog = false"
    />
    <RecurringBillingApproveDialog
      v-if="showEntityDetails"
      :open.sync="showEntityDetails"
      :billing="selectedEntity"
      :show-next-button="showNextButton"
      :show-prev-button="showPrevButton"
      @prev="onPrev"
      @next="onNext"
      @close="showEntityDetails = false"
    />
  </div>
</template>
<script>
import SourceLink from "@/components/links/SourceLink.vue";
import RRuleText from "@/modules/accounts-payable/components/recurring-invoice/RRuleText.vue";
import DownloadChecksDialog from "@/modules/payroll/components/payroll/DownloadChecksDialog.vue";
import SendPayrollChecksForm from "@/modules/payroll/components/checks/SendPayrollChecksForm.vue";
import CheckTemplateSelect from "@/modules/payroll/components/CheckTemplateSelect.vue";
import ConfirmEmailSendDialog from "@/components/common/modal/ConfirmEmailSendDialog.vue";
import TableActionItem from "@/components/table/actions/TableActionItem.vue";
import RecurringInvoiceIcon from "@/modules/accounts-payable/pages/invoices/RecurringInvoiceIcon.vue";
import GenerateBillingsDialog from "@/modules/accounts-receivable/components/recurring-billings/GenerateBillingsDialog.vue";
import RecurringBillingApproveDialog from "@/modules/accounts-receivable/components/recurring-billings/RecurringBillingApproveDialog.vue";
import { CheckCircleIcon, PlayIcon, Trash2Icon } from "vue-feather-icons";

import i18n from "@/i18n";
import { resourceStatuses } from '@/enum/enums'
import { getAddButtonPath } from "@/modules/common/util/costCenterUtils";
import { TableActions } from "@/components/ag-grid/tableUtils";
import { getFrequencyLabelFromPayable } from "@/modules/accounts-payable/components/recurring-invoice/recurringUtils";
import { billingTypeToAbbr, billingTypeAbbr, billingTypes } from '@/modules/accounts-receivable/pages/billings/billings'
import { useEntityReview } from "@/modules/common/composables/useEntityReview";
import CustomerLink from "@/components/links/CustomerLink.vue";

export default {
  components: {
    PlayIcon,
    GenerateBillingsDialog,
    Trash2Icon,
    CheckCircleIcon,
    RRuleText,
    SourceLink,
    TableActionItem,
    ConfirmEmailSendDialog,
    RecurringInvoiceIcon,
    CheckTemplateSelect,
    SendPayrollChecksForm,
    DownloadChecksDialog,
    RecurringBillingApproveDialog,
  },
  props: {
    urlParams: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
    },
    billingStatus: {
      type: String,
    },
  },
  setup() {
    const {
      onNext,
      onPrev,
      setCurrentEntity,
      onDataFetch,
      showPrevButton,
      showNextButton,
      selectedEntity,
      showEntityDetails
    } = useEntityReview()

    return {
      onNext,
      onPrev,
      setCurrentEntity,
      onDataFetch,
      showPrevButton,
      showNextButton,
      selectedEntity,
      showEntityDetails,
    }
  },
  data() {
    return {
      resourceStatuses,
      generateLoading: false,
      showGenerateBillingsDialog: false,
      activeLoading: false,
      addEntityInNewTab: true,
      billingTypeAbbr,
      url: '/restify/recurring-billings',
      billings: [],
      selectedRows: [],
    }
  },
  computed: {
    billingType() {
      return this.$route.meta.billingType || null
    },
    status() {
      return this.$route.meta.status || this.billingStatus || null
    },
    typeAbbr() {
      return billingTypeToAbbr[this.billingType] || null
    },
    isReview() {
      return this.status === resourceStatuses.Review
    },
    TableActions() {
      return TableActions
    },
    addText() {
      if (this.billingType === billingTypes.Service) {
        return this.$t('New recurring service invoice')
      }

      return this.$t('New recurring lump sum billing')
    },
    addEntityUrlQuery() {
      const baseUrl = this.billingType === billingTypes.Service
        ? '/service-billing/recurring-service-invoices/add'
        : '/accounts-receivable/recurring-billings/lump-sum/add'

      return getAddButtonPath(baseUrl, this.urlParams)
    },
    allUrlParams() {
      return {
        sort: 'customer',
        related: 'customer,workOrder',
        status: this.isReview
          ? resourceStatuses.Pending
          : this.status,
        type: this.typeAbbr,
        ...this.urlParams,
      }
    },
    showAdvanceInvoicesAction() {
      return [resourceStatuses.Active].includes(this.status)
    },
    showActivateButton() {
      return [resourceStatuses.Pending].includes(this.status) && !this.isReview
    },
    columns() {
      return [
        {
          headerName: this.$t('Customer'),
          field: 'relationships.customer',
          minWidth: 200,
          maxWidth: 300,
          checkboxSelection: true,
        },
        {
          headerName: i18n.t('Reference'),
          field: 'attributes.cost_center',
          minWidth: 200,
          maxWidth: 350,
        },
        {
          headerName: this.$t('Frequency'),
          field: 'frequency',
          minWidth: 80,
          maxWidth: 200,
        },
        {
          headerName: this.$t('Schedule'),
          field: 'attributes.recurring_rule',
          minWidth: 380,
          maxWidth: 500,
          hide: true,
        },
        {
          headerName: this.$t('Amount'),
          field: 'attributes.gross_amount',
          component: 'FormattedPrice',
          minWidth: 140,
          maxWidth: 180,
          align: 'right',
        },
        {
          headerName: this.$t('Type'),
          align: 'center',
          field: 'attributes.type',
          minWidth: 100,
          maxWidth: 120,
          component: 'Status',
        },
        {
          headerName: this.$t('Status'),
          align: 'center',
          field: 'attributes.status',
          minWidth: 80,
          maxWidth: 100,
          component: 'StatusLink',
        },
        {
          headerName: this.$t('Review Status'),
          align: 'center',
          field: 'attributes.review_status',
          minWidth: 100,
          maxWidth: 140,
          component: 'ReviewStatus',
        },
        {
          headerName: this.$t('Post Automatically'),
          align: 'center',
          field: 'attributes.should_post',
          minWidth: 100,
          maxWidth: 120,
          component: 'Status',
        },
        {
          headerName: this.$t('Send Automatically'),
          align: 'center',
          field: 'attributes.send_automatically',
          minWidth: 100,
          maxWidth: 120,
          component: 'Status',
        },
        {
          headerName: this.$t('Last Issue At'),
          align: 'center',
          field: 'attributes.last_issue_at',
          minWidth: 160,
          maxWidth: 200,
          component: 'FormattedDate',
          hide: true,
        },
        {
          headerName: this.$t('Next Recurrence'),
          align: 'center',
          field: 'attributes.next_recurrence_at',
          minWidth: 160,
          maxWidth: 200,
          component: 'FormattedDate',
          hide: true,
        },
        {
          headerName: this.$t('Created By'),
          field: 'attributes.created_by',
          minWidth: 120,
          maxWidth: 180,
          component: 'UserLink',
          hide: true,
        },
      ]
    },
  },
  methods: {
    getViewPath(row) {
      if (row.attributes.type === billingTypeAbbr.Service) {
        return `/service-billing/recurring-service-invoices/${row.id}/view`
      }

      return `/accounts-receivable/recurring-billings/lump-sum/${row.id}/view`
    },
    getEditPath(row) {
      if (row.attributes.type === billingTypeAbbr.Service) {
        return `/service-billing/recurring-service-invoices/${row.id}/edit`
      }

      return `/accounts-receivable/recurring-billings/lump-sum/${row.id}/edit`
    },
    async activateInvoices() {
      try {
        this.activeLoading = true
        const confirmed = await this.$confirm({
          title: this.$t('Activate Recurring Billing(s)'),
          description: this.$t('By making the recurring billing(s) active, they will start creating new billings based on the provided schedule. Are you sure?'),
          buttonText: this.$t('Activate')
        })
        if (!confirmed) {
          return
        }
        const invoiceIds = this.selectedRows.map(i => i.id)
        for (let id of invoiceIds) {
          await this.$store.dispatch('accountsReceivable/activateRecurringBilling', id)
        }
        await this.refreshData()
      } catch (err) {
        if (err.handled) {
          return
        }
      } finally {
        this.activeLoading = false
      }
    },
    async activateInvoice(row) {
      const confirmed = await this.$confirm({
        title: this.$t('Activate Recurring Billing'),
        description: this.$t('By making the recurring billing active, they will start creating new billings based on the provided schedule. Are you sure?'),
        buttonText: this.$t('Activate')
      })
      if (!confirmed) {
        return
      }
      await this.$store.dispatch('accountsReceivable/activateRecurringBilling', row.id)
      await this.refreshData()
    },
    getFrequencyLabelFromPayable,
    onBillingsGenerated() {
      this.refreshData()
      this.showGenerateBillingsDialog = false
    },
    async refreshData() {
      await this.$refs.table?.refresh()
    },
    onTableDataFetch(billings) {
      this.billings = billings
      this.onDataFetch(billings)
    }
  },
}
</script>
