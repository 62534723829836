<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    size="lg"
    :title="$t('Generate Billings')"
    :appendToBody="true"
  >
    <BaseForm
      layout="modal"
      :show-cancel="true"
      :save-text="$t('Generate Billings')"
      :loading="loading"
      @cancel="$emit('close')"
      @submit="onSubmit"
    >
      <BaseAlert class="col-span-6 mb-4">
        {{ $t('This action will generate billings for the selected recurring receivables until ') }}
        {{ $formatDate(model.date) }}
      </BaseAlert>

      <BaseDatePicker
        v-model="model.date"
        :label="$t('Date Until')"
        :placeholder="$t('Date until')"
        :value-format="null"
        class="col-span-6 md:col-span-3"
      />
      <BaseSwitch
        v-model="model.force"
        :label-info="$t('Force Initial Start Date')"
        :placeholder="$t('Force Initial Start Date')"
        class="col-span-6 md:col-span-3"
      />
      <h5 class="form-section-title">
        {{ $t('Billings to generate') }}
      </h5>
      <div
        v-for="billing in sortedRecurringBillings"
        :key="billing.id"
        class="col-span-6"
      >
        <h5 class="form-section-title mb-1">
          <span class="font-medium">{{ $t('For Recurring Receivable ') }} {{ billing.attributes.description }} {{ $t('for ') }}</span>
          <CustomerLink
            :id="billing.attributes.customer_id"
            showName
            class="ml-1"
          />
          <span v-if="getStartDate(billing)" class="ml-1 font-medium flex items-center space-x-1">
            {{ $t('starting from ') }} {{ $formatDate(getStartDate(billing)) }}
            <BaseTip
              v-if="hasIssuedBillings(billing) && !model.force"
            >
              <template #content>
                <div class="max-w-[300px]">
                  {{$t('This recurring receivable already has issued billings. The start date considered is the last issued billing based on the recurring receivable. If you want to generate billings with the initial start date, use the Force Initial Start Date option.')}}
                </div>
              </template>
            </BaseTip>
          </span>
        </h5>
        <AgDataTable
          :data="getRecurrenceList(billing)"
          :columns="columns"
          :pagination="false"
          :no-data-text="$t('No billings to generate based on the selected dates.')"
          domLayout="autoHeight"
          class="mb-4"
        />
      </div>
    </BaseForm>
  </BaseFormDialog>
</template>
<script>
import { rrulestr } from "rrule";
import i18n from "@/i18n";
import axios from "axios";
import { ApiDateFormat } from "@/plugins/dateFormatPlugin";
import { addMinutes } from "date-fns";
import orderBy from "lodash/orderBy";

export default {
  props: {
    recurringBillings: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      loading: false,
      model: {
        date: new Date(),
        repositories: this.recurringBillings.map(r => r.id),
        force: false,
      }
    }
  },
  computed: {
    sortedRecurringBillings() {
      const invoiceConfigs = this.recurringBillings.map(billing => {
        return {
          ...billing,
          count: this.getRecurrenceList(billing)?.length
        }
      })
      return orderBy(invoiceConfigs, 'count', 'desc')
    },
    columns() {
      return [
        {
          headerName: this.$t('Date'),
          field: 'date',
          component: 'FormattedDate',
          minWidth: 150,
        },
        {
          label: this.$t('Amount'),
          prop: 'gross_amount',
          component: 'FormattedPrice',
          minWidth: 140,
          maxWidth: 180,
          align: 'right',
        },
        {
          label: this.$t('Type'),
          align: 'center',
          prop: 'type',
          minWidth: 100,
          maxWidth: 120,
          component: 'Status',
        },
        {
          label: i18n.t('Post Automatically'),
          align: 'center',
          prop: 'should_post',
          minWidth: 100,
          maxWidth: 120,
          component: 'Status',
        },
      ]
    }
  },
  methods: {
    getRecurrenceList(billing) {
      const rule = rrulestr(billing.attributes.recurring_rule)
      rule.options.until = this.model.date
      if (billing.attributes.last_issue_at && !this.model.force) {
        rule.options.dtstart = new Date(billing.attributes.last_issue_at)
      }
      if (rule.options.dtstart && billing.attributes.last_issue_at) {
        // This is done to skip displaying again the last issue at
        rule.options.dtstart = addMinutes(rule.options.dtstart, 1)
      }
      const recurrenceList = rule.all()
      return recurrenceList.map(date => ({
        ...billing.attributes,
        id: crypto.randomUUID(),
        date,
      }))
    },
    getStartDate(billing) {
      if (this.model.force) {
        return billing.attributes.start_date
      }
      return billing.attributes.last_issue_at || billing.attributes.start_date
    },
    hasIssuedBillings(billing) {
      return billing.attributes.last_issue_at
    },
    async onSubmit() {
      try {
        this.loading = true
        await axios.post(`/restify/recurring-billings/actions?action=advance-recurrence`, {
          repositories: this.model.repositories,
          date: this.$formatDate(this.model.date, ApiDateFormat),
          force: this.model.force,
        })
        this.$emit('save')
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not generate billings'))
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
