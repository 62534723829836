<template>
  <BaseDataTable
    v-bind="defaultAttributes"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    :data-loading="dataLoading"
    :has-summary="false"
    @force-pagination="forcePagination"
  >
    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{ row }">
      <div class="flex">
        <template v-if="row.header?.job">
          <div class="w-1/3 flex">
            {{ $t('Job:') }} &nbsp;
            <JobLink
              :data="row.header.job"
              :show-preview="false"
              :show-name="true"
            />
          </div>
          <template v-if="get(row, 'header.customer.id')">
            {{ $t('Customer:') }} &nbsp;
            <CustomerLink
              :data="row.header.customer"
              :show-preview="false"
              :show-name="true"
            />
          </template>
        </template>
        <template v-else-if="row.header?.line_item">
          <LineItemLink
            :data="row.header.line_item"
            :show-preview="false"
            :show-name="true"
          />
        </template>
      </div>
    </template>
    
    <template #vendor_employee_customer="{ row }">
      <EmployeeLink
        v-if="row.vendor_employee_customer?.employee"
        :data="row.vendor_employee_customer?.employee"
        :show-preview="false"
      />
      <VendorLink
        v-else-if="row.vendor_employee_customer?.vendor"
        :data="row.vendor_employee_customer?.vendor"
        :show-preview="false"
      />
      <CustomerLink
        v-else-if="row.vendor_employee_customer?.customer"
        :data="row.vendor_employee_customer?.customer"
        :show-preview="false"
      />
      <span v-else> - </span>
    </template>

    <template #subtotal="{subtotal}">
      <td colspan="3" />

      <td align="right" style="vertical-align: top;">
        {{ $t(subtotal.name) }}
      </td>
      <td />

      <td v-for="(costKey, index) of costKeys" align="right" style="vertical-align: top;">
        <div>{{ $formatPrice(subtotal.totals[costKey]) }}</div>
        <template v-if="index === costKeys.length - 1">
          <template v-if="subtotal.job_totals || subtotal.grand_totals">
            <div>{{ $t('Total Costs:') }}</div>
            <div class="my-2 border-t border-gray-700"></div>
            <div>{{ $t('Billed Less Cost:') }}</div>
          </template>
          <div>{{ $t('Total Labor Hours:') }}</div>
        </template>
      </td>
      <td align="right" style="vertical-align: top;">
        <div>{{ $formatPrice(subtotal.totals.Billed) }}</div>
        <template v-if="subtotal.job_totals || subtotal.grand_totals">
          <div>{{ $formatPrice(subtotal.totals.totalCost)  }}</div>
          <div class="my-2 border-gray-700 border-t -ml-2"></div>
          <div>{{ $formatPrice(subtotal.totals.Billed - subtotal.totals.totalCost)  }}</div>
        </template>
        <div>{{ $formatHours(subtotal.totals.hours) }}</div>
      </td>
    </template>
  </BaseDataTable>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import {
  preBillingViewFormats,
  PreBillingReportOptions,
  QuantityLabels,
} from '@/modules/accounts-receivable/components/reports/util'

export default {
  extends: ReportTableWrapper,
  props: {
    dataLoading: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => ({}),
    }
  },
  computed: {
    jobCostTypes() {
      return this.$store.getters['globalLists/getResourceOptions'](this.$globalResources.JobCostTypes) || []
    },
    costKeys() {
      return this.jobCostTypes.map(costType => costType.abbr)
    },
    jobCostTypeColumns() {
      return this.jobCostTypes.map(costType => {
        return {
          label: costType.name,
          prop: `amounts.${costType.abbr}`,
          align: 'right',
          minWidth: 130,
          maxWidth: 160,
          component: 'FormattedPrice',
          hideZero: true,
        }
      })
    },
    columns() {
      return [
        {
          label: this.$t('Date'),
          prop: 'date',
          component: 'FormattedDate',
          minWidth: 130,
          maxWidth: 160,
        },
        {
          label: this.$t('Vendor/<br>Empl/Cust'),
          prop: 'vendor_employee_customer',
          minWidth: 130,
          maxWidth: 160,
        },
        {
          label: this.$t('Ref No.'),
          prop: 'reference_no',
          minWidth: 130,
          maxWidth: 160,
        },
        {
          label: this.$t('Description'),
          prop: 'description',
          minWidth: 130,
          maxWidth: 160,
        },
        {
          label: this.$t('Units <br> LAB/MTL/EQP'),
          prop: 'units',
          align: 'right',
          minWidth: 130,
          maxWidth: 160,
        },
        ...this.jobCostTypeColumns,
        {
          label: this.$t('Billed'),
          prop: 'amounts.Billed',
          align: 'right',
          minWidth: 130,
          maxWidth: 160,
          component: 'FormattedPrice',
          hideZero: true,
        },
      ]
    },
    format() {
      return this.filters.format
    },
    sort_by() {
      return this.filters.sort_by
    }
  },
  methods: {
    addJobGroup(jobGroup) {
      this.rows.push({
        header: {
          job: jobGroup.job,
          customer: jobGroup.customer,
        }
      })

      this.addJobDetailLines(jobGroup)

      this.addJobTotals(jobGroup)
    },
    addJobDetailLines(jobGroup) {
      // When sorted by phase we still need to add at least phase totals
      if (this.sort_by === PreBillingReportOptions.SortBy.PhaseCostCodeDate) {
        this.addItemsSortedByPhase(jobGroup)
        return
      }

      // Nothing else to add when sorted by date & format = summary
      if (this.format !== preBillingViewFormats.JCAndBillWorksheetDetails) {
        return
      }

      this.addItemsSortedByDate(jobGroup)
    },
    addItemsSortedByDate(jobGroup) {
      for (let dateGroup of jobGroup.items) {
        const date = dateGroup.date

        for (let typeGroup of dateGroup.items) {
          const type = typeGroup.type
          const type_abbr = type.abbr

          for (let vendorEmployeeGroup of typeGroup.items) {
            const vendor_employee_customer = {
              employee: vendorEmployeeGroup.employee,
              vendor: vendorEmployeeGroup.vendor,
              customer: vendorEmployeeGroup.customer,
            }

            for (let vendorEmployeeItem of vendorEmployeeGroup.items) {
              const {
                craft,
                entry,
                line_item,
                quantity,
                sort_value,
                rate,
                total
              } = vendorEmployeeItem

              const units = entry?.quantity
                ? this.$formatQuantity(entry.quantity) + '' + (QuantityLabels[entry?.quantity_from] || entry?.quantity_from ||'')
                : ''

              let originalDate = date

              const prevDate = this.rows[this.rows.length - 1]?.originalDate

              const lineDate = originalDate === prevDate ? '' : originalDate

              const lineResult = {
                description: entry?.description || craft?.description,
                date: lineDate,
                originalDate,
                vendor_employee_customer,
                reference_no: entry?.reference_no || '',
                amounts: {
                  [type_abbr]: total,
                },
                units,
              }

              this.rows.push(lineResult)
            }
          }
        }
      }
    },
    addItemsSortedByPhase(jobGroup) {
      for (let phaseGroup of jobGroup.items) {
        let phaseHeaderAdded = false
        const phase = phaseGroup.phase

        for (let typeGroup of phaseGroup.items) {
          const type = typeGroup.type
          const type_abbr = type.abbr

          for (let vendorEmployeeItem of typeGroup.items) {
            const {
              craft,
              entry,
              line_item,
              quantity,
              employee_id,
              sort_value,
              rate,
              total
            } = vendorEmployeeItem

            if (!phaseHeaderAdded) {
              this.rows.push({
                header: {
                  line_item,
                }
              })
              phaseHeaderAdded = true
            }

            // TODO: Workaround vendor/employee/customer not returned when sort_by = phase
            let vendor_employee_customer = {}

            if (employee_id) {
              vendor_employee_customer = {
                employee: {
                  id: employee_id,
                  code: sort_value,
                }
              }
            }
            else {
              vendor_employee_customer = entry.business_type === 'customer'
                ? { customer: { id: entry.business_id, code: sort_value } }
                : { vendor: { id: entry.business_id, code: sort_value } }
            }

            const units = entry?.quantity
              ? this.$formatQuantity(entry.quantity) + '' + (QuantityLabels[entry?.quantity_from] || entry?.quantity_from ||'')
              : ''

            let originalDate = entry?.date || entry?.reference_date

            const prevDate = this.rows[this.rows.length - 1]?.originalDate

            const date = originalDate === prevDate ? '' : originalDate

            const lineResult = {
              description: entry?.description || craft?.description,
              date,
              originalDate,
              vendor_employee_customer,
              reference_no: entry?.reference_no || '',
              amounts: {
                [type_abbr]: total,
              },
              units,
            }

            if (this.format === preBillingViewFormats.JCAndBillWorksheetDetails) {
              this.rows.push(lineResult)
            }
          }
        }

        this.rows.push({
          subtotal: {
            name: this.$t('Phase / Cost Code Totals') +  ` (${phase})`,
            totals: {
              ...phaseGroup.totals,
            },
          }
        })
      }
    },
    addJobTotals(jobGroup) {
      const totalCost = this.costKeys.reduce((acc, key) => {
        acc += jobGroup.totals[key] || 0
        return acc
      }, 0)

      this.rows.push({
        subtotal: {
          name: this.$t('Job Totals') +  ` (${jobGroup.job.number})`,
          job_totals: true,
          totals: {
            ...jobGroup.totals,
            totalCost,
          },
        }
      })
    },
    addGrandTotals(data) {
      const grand_total_keys = [
        ...this.costKeys,
        'Billed',
        'hours'
      ]

      const grand_totals = grand_total_keys.reduce((acc, key) => {

        acc[key] = data.reduce((acc, jobGroup) => {
          acc += jobGroup.totals[key] || 0
          return acc
        }, 0)

        return acc
      }, {})

      const grandTotalCost = this.costKeys.reduce((acc, key) => {
        acc += grand_totals[key] || 0
        return acc
      }, 0)

      this.rows.push({
        subtotal: {
          name: this.$t('Grand Total All Jobs'),
          grand_totals: true,
          totals: {
            ...grand_totals,
            totalCost: grandTotalCost,
          },
        }
      })
    },
    composeRows(data) {
      data.forEach(jobGroup => {
        this.addJobGroup(jobGroup)
      })

      this.addGrandTotals(data)
      return this.rows
    },
  },
}
</script>
