<template>
  <LiveChatButtons :supports-video="true"/>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import useLiveApi from "@/modules/support/composables/useLiveApi";
import LiveChatButtons from "@/modules/support/components/LiveChatButtons.vue";
import store from "@/store";
import { useAllRoutes } from "@/modules/dashboard/composables/useAllRoutes";
import { can } from "@/modules/auth/plugins/permissionsPlugin";
import orderBy from "lodash/orderBy";
import { globalResources } from "@/components/form/util";

const { client, setConfig } = useLiveApi();

const user = computed(() => {
  return store.state.auth?.user || {}
})

const { cleanRoutes } = useAllRoutes()
const companyContext = computed(() => {
  const globalLists = store.state.globalLists
  const contextMap = {
    routes: {
      description: 'All the application pages the user has access to',
      data: cleanRoutes.value
    },
    // accounts: {
    //   description: 'General Ledger Accounts',
    //   show: can('accounts_show'),
    //   data: store.state.company.accounts,
    // },
    // vendors: {
    //   description: 'Accounts Payable Vendors',
    //   show: can('vendors_show'),
    //   data: orderBy(globalLists[globalResources.Vendors], 'current_ap_amount', 'desc').slice(0, 50)
    // },
    // customers: {
    //   description: 'Accounts Receivable Customers',
    //   show: can('customers_show'),
    //   data: globalLists[globalResources.Customers]
    // }
  }
  return contextMap
})

const systemInstructions = computed(() => {
  return `You are a helpful accounting assistant for a software product called Construction Partner. You are helping users navigate and understand the Construction Partner application.
          ------------------------------------------------
          General suggestions
          - If you don't have enough context, you can always ask the user to share his screen or share the current page content.
          - Try not interrupt the user while they are typing and don't act overly helpful or intrusive.
          - Don't assume things you don't know for sure or things that cannot be found on the internet.
          ------------------------------------------------
          This is the current user information. You can use this information to help the current user you are speaking to.
          ${JSON.stringify(user.value)}
          ------------------------------------------------
          This is the general product information. You can use this information to help the current user you are speaking to.
          ${JSON.stringify(companyContext.value)}
          `
})

const apiConfig = computed(() => {
  return {
    model: 'models/gemini-2.0-flash-exp',
    generationConfig: {
      responseModalities: 'audio',
      speechConfig: {
        voiceConfig: { prebuiltVoiceConfig: { voiceName: 'Puck' } },
      },
    },
    systemInstruction: {
      parts: [
        {
          text: systemInstructions.value,
        },
      ],
    },
    tools: [
      { googleSearch: {} },
      { functionDeclarations: [] },
    ],
  }
})

watch(() => apiConfig.value, (newVal) => {
  setConfig(newVal);
})
onMounted(() => {
  setConfig(apiConfig.value);

  const onToolCall = (toolCall) => {
    console.log('got toolcall', toolCall);
  };
  client.value.on('toolcall', onToolCall);

  onUnmounted(() => {
    client.value.off('toolcall', onToolCall);
  });
});
</script>

<style scoped>
/* Add relevant styles */
</style>
