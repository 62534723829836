<template>
  <BaseReportForm
    :filters="model"
    :report-number="reportNumber"
    :exportNumber="exportNumber"
    :canExportToCsv="model.format === preBillingViewFormats.LineItemSummary"
    :submitDisabled="hasCostTypeFilter && !jobCostTypes.length"
    @data-fetch="onDataFetch"
  >
    <template #filters>

      <BaseFilterRow :title="$t('View')">

        <BaseSelect
          v-model="model.format"
          :options="formatOptions"
          class="col-span-3"
          rules="required"
        />
        <div
          v-if="availableSortOptions.length"
          class="col-span-3 flex items-baseline"
        >
          <span class="text-label">{{ $t('Sort By') }}</span>
          <BaseSelect
            v-model="model.sort_by"
            :options="availableSortOptions"
            :name="$t('Sort By')"
            class="flex-1"
            rules="required"
          />
        </div>

      </BaseFilterRow>

      <BaseFilterRow
        v-if="showMarkupPercentages"
        :title="$t('Markup Percentages')"
      >
        <div
          v-if="!jobCostTypes.length"
          class="col-span-6 text-sm text-gray-500"
        >
          {{ $t('Loading cost types...') }}
        </div>
        <div
          v-else
          class="flex space-x-2 col-span-6"
        >
          <BaseInput
            v-for="costType of jobCostTypes"
            :key="costType.id"
            v-model="model.markups[costType.id]"
            :label="costType.abbr"
            :placeholder="costType.abbr"
            :name="costType.abbr"
            type="number"
            format="percent"
            class="w-1/6"
          />
        </div>
      </BaseFilterRow>

      <BaseFilterRow
        v-if="model.format === preBillingViewFormats.LineItemSummary"
        :title="$t('Billing Types')"
      >
        <BaseSelect
          v-model="model.billing_types"
          :options="billingTypes"
          :name="$t('Billing Type')"
          value-key="id"
          label-key="abbr"
          class="col-span-3"
          rules="required"
          collapse-tags
          multiple
        />
      </BaseFilterRow>

      <BaseFilterRow
        v-if="hasCostTypeFilter"
        :title="$t('Cost Types')"
      >        
        <BaseSelect
          v-model="model.job_type_ids"
          v-loading="!jobCostTypes.length"
          :add-entity="false"
          :options="jobCostTypes"
          value-key="id"
          label-key="name"
          class="col-span-3"
          collapse-tags
          multiple
        />
      </BaseFilterRow>

      <BaseFilterRow
        v-if="hasDateRangeFilter"
        :title="$t('Dates')"
      >
        <DateRangeFilter
          v-model="model"
          class="col-span-4"
        />
      </BaseFilterRow>

      <BaseFilterRow
        v-if="model.format === preBillingViewFormats.LineItemSummary"
      >
        <div class="col-span-6 flex mb-4">
          <BaseCheckbox
            v-model="model.include_cost_to_date"
            :label="$t('Include Cost To-Date')"
            id="include_cost_to_date"
          />
          <BaseCheckbox
            v-model="model.include_remaining_to_bill"
            :label="$t('Include Remaining to Bill')"
            id="include_remaining_to_bill"
          />
        </div>
      </BaseFilterRow>

      <BaseFilterRow
        v-if="hasCalculatedPayrollFilter"
      >
        <div class="col-span-6 flex mb-4">
          <BaseCheckbox
            v-model="model.include_calculated_current_payroll"
            :label="$t('Include Calculated Current Payroll')"
            id="include_calculated_current_payroll"
          />
        </div>
      </BaseFilterRow>

      <JobAndAdditionalFilters v-model="model">
        <template #job-statuses>
          <div class="col-span-2 flex items-baseline">
            <span class="text-label">{{ $t('Status') }}</span>
            <BaseSelect
              v-model="model.job_statuses"
              :add-entity="false"
              :options="jobStatusOptions"
              class="flex-1"
              rules="required"
              collapse-tags
              multiple
            />
          </div>
        </template>
      </JobAndAdditionalFilters>

      <LineItemRangeFilter v-model="model" />

    </template>

    <template #table="{data, loading}">
      <TimeAndMaterialReportTable
        v-if="isTimeAndMaterialFormat"
        :data="data"
        :filters="model"
        :report-number="reportNumber"
        :data-loading="loading"
      />
      <JCAndBillWorksheetReportTable
        v-else-if="isJCAndBillFormat"
        :data="data"
        :filters="model"
        :report-number="reportNumber"
        :data-loading="loading"
      />
      <JobPreBillingWorksheetReportTable
        v-else
        :data="getData"
        :filters="model"
        :report-number="reportNumber"
        :data-loading="loading"
        :billing-type="currentTab"
      >
        <template #header-tabs>
          <BaseTabs
            v-model="currentTab"
            :tabs="selectedBillings"
            value-key="abbr"
          />
        </template>
      </JobPreBillingWorksheetReportTable>
    </template>
  </BaseReportForm>
</template>
<script>
import { jobStatusOptions } from '@/modules/job-costing/enum/jobs'
import { JobAndAdditionalFilters, LineItemRangeFilter } from '@/components/range-filters'
import {
  preBillingViewFormats,
  PreBillingReportOptions
} from '@/modules/accounts-receivable/components/reports/util'
import JobPreBillingWorksheetReportTable from '@/modules/accounts-receivable/components/reports/job-pre-billing-worksheet/JobPreBillingWorksheetReportTable.vue'
import JCAndBillWorksheetReportTable from '@/modules/accounts-receivable/components/reports/job-pre-billing-worksheet/JCAndBillWorksheetReportTable.vue'
import TimeAndMaterialReportTable from '@/modules/accounts-receivable/components/reports/job-pre-billing-worksheet/TimeAndMaterialReportTable.vue'
import DateRangeFilter from '@/components/range-filters/DateRangeFilter.vue'

  export default {
    components: {
      LineItemRangeFilter,
      JobAndAdditionalFilters,
      JobPreBillingWorksheetReportTable,
      JCAndBillWorksheetReportTable,
      TimeAndMaterialReportTable,
      DateRangeFilter,
    },
    data() {
      const billingTypeIds = this.$store.getters['jobCosting/getBillingTypeIds']

      return {
        reportData: [],
        selectedBillings: [],
        reportHeader: {},
        currentTab: '',
        jobStatusOptions,
        preBillingViewFormats,
        model: {
          format: preBillingViewFormats.LineItemSummary,
          billing_types: billingTypeIds,
          start_date: null,
          end_date: null,
          customer_code_from: null,
          customer_code_to: null,
          job_ids: [],
          job_number_from: null,
          job_number_to: null,
          job_statuses: [this.$resourceStatuses.Open],
          phase_code_from: null,
          phase_code_to: null,
          phase_codes: null,
          cost_code_from: null,
          cost_code_to: null,

          include_cost_to_date: true,
          include_remaining_to_bill: false,

          // 5889 B + E
          job_type_ids: [],
          sort_by: null,
          include_calculated_current_payroll: false,
          start_date: null,
          end_date: null,
          page: false,
          // T & M
          markups: {}
        },
        formatOptions: [
          {
            label: this.$t('Line Item Summary'),
            value: preBillingViewFormats.LineItemSummary,
          },
          // TODO: Disabled for now, BE not ready
          // {
          //   label: this.$t('JC & Bill Worksheet - Detail'),
          //   value: preBillingViewFormats.JCAndBillWorksheetDetails,
          // },
          // {
          //   label: this.$t('JC & Bill Worksheet - Summary'),
          //   value: preBillingViewFormats.JCAndBillWorksheetSummary,
          // },
          {
            label: this.$t('Time & Material - W/ History'),
            value: preBillingViewFormats.TMHistory,
          },
          {
            label: this.$t('Time & Material - W/ Markups'),
            value: preBillingViewFormats.TMMarkups,
          },
          {
            label: this.$t('Time & Material - W/ Actual Cost'),
            value: preBillingViewFormats.TMActualCost,
          },
        ],
      }
    },
    computed: {
      reportNumber() {
        const reportNumbers = {
          [preBillingViewFormats.LineItemSummary]: 5889,
          [preBillingViewFormats.JCAndBillWorksheetDetails]: '5889e',
          [preBillingViewFormats.JCAndBillWorksheetSummary]: '5889e',
          [preBillingViewFormats.TMActualCost]: '5889b',
          [preBillingViewFormats.TMHistory]: '5889b',
          [preBillingViewFormats.TMMarkups]: '5889b',
        }
        return reportNumbers[this.model.format]
      },
      exportNumber() {
        return '5889ae'
      },
      billingTypes() {
        return this.$store.getters['jobCosting/getBillingTypes']
      },
      billingTypeIds() {
        return this.$store.getters['jobCosting/getBillingTypeIds']
      },
      jobCostTypes() {
        return this.$store.getters['globalLists/getResourceOptions'](this.$globalResources.JobCostTypes) || []
      },
      getTypeIndexByAbbr() {
        return this.selectedBillings.findIndex(type => type.abbr === this.currentTab)
      },
      getData() {
        const items = this.get(this.reportData, `[${this.getTypeIndexByAbbr}].jobs`, [])

        return {
          header: this.reportHeader,
          items,
        }
      },
      isTimeAndMaterialFormat() {
        return [
          preBillingViewFormats.TMHistory,
          preBillingViewFormats.TMMarkups,
          preBillingViewFormats.TMActualCost,
        ].includes(this.model.format)
      },
      isJCAndBillFormat() {
        return [
          preBillingViewFormats.JCAndBillWorksheetDetails,
          preBillingViewFormats.JCAndBillWorksheetSummary,
        ].includes(this.model.format)
      },
      sortByOptions() {
        return [
          {
            label: this.$t('Date / Cost Type / Vendor'),
            value: PreBillingReportOptions.SortBy.DateCostTypeVendor,
            showIf: () => {
              return this.isTimeAndMaterialFormat || this.isJCAndBillFormat
            }
          },
          {
            label: this.$t('Phase / Cost Code / Date'),
            value: PreBillingReportOptions.SortBy.PhaseCostCodeDate,
            showIf: () => {
              return this.isTimeAndMaterialFormat || this.isJCAndBillFormat
            }
          },
          {
            label: this.$t('Cost Type / Code / Date'),
            value: PreBillingReportOptions.SortBy.CostTypeCodeDate,
            showIf: () => this.isTimeAndMaterialFormat
          }
        ]
      },
      availableSortOptions() {
        return this.sortByOptions.filter(option => !option.showIf || option.showIf())
      },
      hasDateRangeFilter() {
        return this.isTimeAndMaterialFormat || this.isJCAndBillFormat
      },
      hasCostTypeFilter() {
        return this.isTimeAndMaterialFormat
      },
      hasCalculatedPayrollFilter() {
        return this.isJCAndBillFormat || this.model.format === preBillingViewFormats.TMActualCost
      },
      showMarkupPercentages() {
        return [
          preBillingViewFormats.TMMarkups,
          preBillingViewFormats.TMActualCost,
        ].includes(this.model.format)
      }
    },
    methods: {
      onDataFetch(data) {
        this.selectedBillings = data.items?.map(({ job_type }) => job_type)
        this.currentTab = this.get(this.selectedBillings, '[0].abbr', '')

        this.reportHeader = data.header
        this.reportData = data.items || []
      },
    },
    watch: {
      billingTypeIds: {
        immediate: true,
        handler(value) {
          this.model.billing_types = value || []
        },
      },
      jobCostTypes: {
        immediate: true,
        handler(value) {
          this.model.job_type_ids = value.map(costType => costType.id)
          this.model.markups = value.reduce((acc, costType) => {
            acc[costType.id] = this.model.markups[costType.id] || costType.markup_percent
            return acc
          }, {})
        },
      },
      'model.format': {
        async handler() {
          await this.$nextTick()
          if (this.model.format !== preBillingViewFormats.LineItemSummary) {
            this.model.billing_types = []
          }
          else {
            this.model.billing_types = this.billingTypeIds
          }

          if (
            this.availableSortOptions.length &&
            (!this.model.sort_by || !this.availableSortOptions.find(option => option.value === this.model.sort_by))
          ) {
            this.model.sort_by = this.availableSortOptions[0].value
          }
        },
        immediate: true,
      }
    }
  }
</script>
