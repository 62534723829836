<template>
  <div>
    <LayoutTabs
      v-if="!onViewResourcePage"
      :items="filteredItems"
    />
    <router-view
      :billing-type="billingType"
    />
  </div>
</template>
<script>
export default {
  computed: {
    billingType() {
      return this.$route.meta.billingType || 'all'
    },
    status() {
      return this.$route.meta.status || 'all'
    },
    basePath() {
      if (this.$currentModule === this.$modules.SB) {
        return '/service-billing/recurring-service-invoices'
      }
      
      return `/accounts-receivable/recurring-billings/${this.billingType}`
    },
    items() {
      return [
        {
          name: this.$t('Pending'),
          tooltip: this.$t('Pending Recurring Receivables'),
          path: `${this.basePath}/pending`,
          permission: 'recurring_billings_show',
        },
        {
          name: this.$t('Active'),
          tooltip: this.$t('Active Recurring Receivables'),
          path: `${this.basePath}/active`,
          permission: 'recurring_billings_show',
        },
        {
          name: this.$t('Inactive'),
          tooltip: this.$t('Inactive Recurring Receivables'),
          path: `${this.basePath}/inactive`,
          permission: 'recurring_billings_show',
          
        },
        {
          name: this.$t('All'),
          tooltip: this.$t('Recurring Receivables that were not posted yet'),
          path: `${this.basePath}/all`,
          permission: 'recurring_billings_show',
        },
        {
          name: this.$t('Review'),
          tooltip: this.$t('Review Recurring Receivables'),
          path: `${this.basePath}/review`,
          permission: 'recurring_billings_update',
        },
      ]
    },
    filteredItems() {
      return this.items.filter(item => this.$can(item.permission))
    },
  },
}
</script>
