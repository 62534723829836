import EmptyLayout from "@/components/common/EmptyLayout";
import EmployeesMenuLayout from "@/modules/payroll/layout/EmployeesMenuLayout";
import EmployeeList from "@/modules/payroll/pages/employee/employee-list";
import EmployeeLayout from "@/modules/payroll/layout/EmployeeLayout";
import EmployeeDetails from "@/modules/payroll/pages/employee/employee-details";
import EmployeeEdit from "@/modules/payroll/pages/employee/employee-edit";
import EmployeeChecksDesktop from "@/modules/payroll/pages/employee/employee-checks-desktop";
import EmployeeW2Forms from "@/modules/payroll/pages/employee/employee-w2-forms";
import EmployeePayRates from "@/modules/payroll/pages/employee/pay-rates/employee-pay-rates";
import EmployeeBenefits from "@/modules/payroll/pages/employee/benefit-rates/employee-benefit-rates";
import EmployeeDeductionRates from "@/modules/payroll/pages/employee/deduction-rates/employee-deduction-rates";
import EmployeeTimeOff from "@/modules/payroll/pages/employee/time-off/employee-time-off";
import EmployeeDirectDeposit from "@/modules/payroll/pages/employee/direct-deposits/employee-direct-deposit";
import PayRates from "@/modules/payroll/pages/employee/pay-rates/pay-rates";
import PayRatesAdd from "@/modules/payroll/pages/employee/pay-rates/pay-rates-add";
import PayRatesEdit from "@/modules/payroll/pages/employee/pay-rates/pay-rates-edit";
import BenefitsList from "@/modules/payroll/pages/employee/benefit-rates/benefit-rates-list";
import BenefitsAdd from "@/modules/payroll/pages/employee/benefit-rates/benefit-rates-add";
import BenefitsEdit from "@/modules/payroll/pages/employee/benefit-rates/benefit-rates-edit";
import DeductionRates from "@/modules/payroll/pages/employee/deduction-rates/deduction-rates";
import DeductionRatesAdd from "@/modules/payroll/pages/employee/deduction-rates/deduction-rates-add";
import DeductionRatesEdit from "@/modules/payroll/pages/employee/deduction-rates/deduction-rates-edit";
import EmployeeTaxLayout from "@/modules/payroll/layout/EmployeeTaxLayout";
import StateTaxes from "@/modules/payroll/pages/employee/taxes/state-taxes";
import StateTaxJurisdictionsList from "@/modules/payroll/pages/settings/state-tax-jurisdictions-list";
import LocalTaxJurisdictionsList from "@/modules/payroll/pages/settings/local-tax-jurisdictions-list";
import AdjustmentLayout from "@/modules/payroll/layout/AdjustmentLayout";
import Earnings from "@/modules/payroll/pages/adjustment/earnings";
import Benefits from "@/modules/payroll/pages/adjustment/benefits";
import Taxes from "@/modules/payroll/pages/adjustment/taxes";
import Deductions from "@/modules/payroll/pages/adjustment/deductions";
import W2Memos from "@/modules/payroll/pages/adjustment/w2-memos";
import ReviewAdjustments from "@/modules/payroll/pages/adjustment/forms/edit";
import AddAdjustments from "@/modules/payroll/pages/adjustment/forms/add";
import DirectDepositsList from "@/modules/payroll/pages/employee/direct-deposits/direct-deposits-list";
import DirectDepositsAdd from "@/modules/payroll/pages/employee/direct-deposits/direct-deposits-add";
import DirectDepositsEdit from "@/modules/payroll/pages/employee/direct-deposits/direct-deposits-edit";
import W2FormsBatchesList from "@/modules/payroll/pages/w2-forms/w2-forms-batches-list";
import W2Forms from "@/modules/payroll/pages/w2-forms/w2-forms";
import CheckBatches from "@/modules/payroll/pages/CheckBatches";
import CodesLayout from "@/modules/payroll/layout/CodesLayout";
import CraftCodesList from "@/modules/payroll/pages/codes/craft-codes/craft-codes-list";
import PayCodesList from "@/modules/payroll/pages/codes/pay-codes-list";
import BenefitCodesList from "@/modules/payroll/pages/codes/benefit-codes-list";
import DeductionCodesList from "@/modules/payroll/pages/codes/deduction-codes-list";
import TimeOffList from "@/modules/payroll/pages/codes/time-off/time-off-list";
import TimeOffCreate from "@/modules/payroll/pages/codes/time-off/time-off-create";
import TimeOffPolicyLayout from "@/modules/payroll/layout/TimeOffPolicyLayout";
import TimeOffDetails from "@/modules/payroll/pages/codes/time-off/time-off-details";
import TimeOffEdit from "@/modules/payroll/pages/codes/time-off/time-off-edit";
import TaxCodesList from "@/modules/payroll/pages/codes/tax-codes-list";
import OcipCodesList from "@/modules/payroll/pages/codes/ocip-codes-list";
import DepartmentCodes from "@/modules/payroll/pages/codes/department-codes";
import RatesLayout from "@/modules/payroll/layout/RatesLayout";
import TimeOffRatesList from "@/modules/payroll/pages/rates/time-off-rates-list";
import JobRatesList from "@/modules/payroll/pages/rates/job-rates-list";
import JobRateAdd from "@/modules/payroll/pages/rates/job-rate-add";
import JobRateEdit from "@/modules/payroll/pages/rates/job-rate-edit";
import UnionsList from "@/modules/payroll/pages/rates/unions-list";
import UnionAdd from "@/modules/payroll/pages/rates/union-add";
import UnionEdit from "@/modules/payroll/pages/rates/union-edit";
import GeneralLiabilityRatesList from "@/modules/payroll/pages/rates/general-liability-rates-list";
import WorkerCompensationRatesList from "@/modules/payroll/pages/rates/worker-compensation-rates-list";
import CopyDeleteRates from "@/modules/payroll/pages/rates/copy-delete-rates";
import TimecardBatchesLayout from "@/modules/payroll/layout/TimecardBatchesLayout";
import TimecardImport from "@/modules/payroll/pages/timecard/timecard-import";
import PendingTimecardBatches from "@/modules/payroll/pages/timecard/batch/pending-timecard-batches";
import ComputedTimecardBatches from "@/modules/payroll/pages/timecard/batch/computed-timecard-batches";
import PostedTimecardBatches from "@/modules/payroll/pages/timecard/batch/posted-timecard-batches";
import VoidedTimecardBatches from "@/modules/payroll/pages/timecard/batch/voided-timecard-batches";
import AllTimecardBatches from "@/modules/payroll/pages/timecard/batch/all-timecard-batches";
import timecardBatchAdd from "@/modules/payroll/pages/timecard/timecard-batch-add";
import TimecardBatchAdd from "@/modules/payroll/pages/timecard/timecard-batch-add";
import TimecardBatchLayout from "@/modules/payroll/layout/TimecardBatchLayout";
import TimecardBatchView from "@/modules/payroll/pages/timecard/timecard-batch-view";
import TimecardBatchBySource from "@/modules/payroll/pages/timecard/timecard-batch-by-source";
import TimecardEdit from "@/modules/payroll/pages/timecard/timecard-edit";
import PayrollBatchesLayout from "@/modules/payroll/layout/PayrollBatchesLayout";
import ComputedPayrollBatches from "@/modules/payroll/pages/payroll/batch/computed-payroll-batches";
import PostedPayrollBatches from "@/modules/payroll/pages/payroll/batch/posted-payroll-batches";
import VoidedPayrollBatches from "@/modules/payroll/pages/payroll/batch/voided-payroll-batches";
import AllPayrollBatches from "@/modules/payroll/pages/payroll/batch/all-payroll-batches";
import PayrollBatchView from "@/modules/payroll/pages/payroll/batch/payroll-batch-view";
import PayrollCheckBatchDetails from "@/modules/payroll/pages/checks/payroll-check-batch-details";
import PayrollJournals from "@/modules/common/components/journal/journal";
import PayrollJournalDetails from "@/modules/common/components/journal/journal-details";
import Reports from "@/modules/payroll/layout/Reports";
import PayrollHistoryByEmployeeReport
  from "@/modules/payroll/components/reports/payroll-history-by-employee/PayrollHistoryByEmployeeReport";
import QuarterlyEarningsFederalReport from "@/modules/payroll/components/reports/QuarterlyEarningsFederalReport";
import QuarterlyEarningsStateLocalReport from "@/modules/payroll/components/reports/QuarterlyEarningsStateLocalReport";
import CertifiedPayrollReport from "@/modules/payroll/components/reports/CertifiedPayrollReport";
import SettingsLayout from "@/modules/payroll/layout/SettingsLayout";
import PayrollSettings from "@/modules/payroll/pages/settings/payroll-settings.vue";
import PayrollCheckBatches from "@/modules/payroll/pages/checks/payroll-check-batches";
import EmployeeImport from "@/modules/payroll/pages/employee/employee-import.vue";
import CraftCodesImport from "@/modules/payroll/pages/codes/craft-codes/craft-codes-import.vue";
import OpenCalendarYear from "@/modules/payroll/pages/settings/open-calendar-year.vue";
import { $modules } from "@/enum/enums";
import SettingsActivityLog from "@/modules/common/components/SettingsActivityLog.vue";
import BenefitCodesImport from "@/modules/payroll/pages/codes/benefit-codes-import.vue";
import OcipCodesImport from "@/modules/payroll/pages/codes/ocip-codes-import.vue";
import RetirementPlansList from "@/modules/payroll/pages/codes/retirement-plans-list.vue";
import RetirementRates from "@/modules/payroll/pages/employee/retirement-rates/retirement-rates.vue";
import RetirementRatesAdd from "@/modules/payroll/pages/employee/retirement-rates/retirement-rates-add.vue";
import RetirementRatesEdit from "@/modules/payroll/pages/employee/retirement-rates/retirement-rates-edit.vue";
import EmployeeRetirementRates from "@/modules/payroll/pages/employee/retirement-rates/employee-retirement-rates.vue";
import FlexibleBenefitPlansList from "@/modules/payroll/pages/codes/flexible-benefit-plans-list.vue";
import EmployeeFlexibleBenefitRates
  from "@/modules/payroll/pages/employee/flexible-benefit-rates/employee-flexible-benefit-rates.vue";
import FlexibleBenefitRatesList
  from "@/modules/payroll/pages/employee/flexible-benefit-rates/flexible-benefit-rates-list.vue";
import FlexibleBenefitRatesAdd
  from "@/modules/payroll/pages/employee/flexible-benefit-rates/flexible-benefit-rates-add.vue";
import FlexibleBenefitRatesEdit
  from "@/modules/payroll/pages/employee/flexible-benefit-rates/flexible-benefit-rates-edit.vue";
import EmployeeRatesLayout from "@/modules/payroll/layout/EmployeeRatesLayout.vue";
import EmployeeTaxesLayout from "@/modules/payroll/layout/EmployeeTaxesLayout.vue";
import UnionReport from "@/modules/payroll/pages/reports/union-report.vue";
import TimecardBatchProof from "@/modules/payroll/pages/timecard/timecard-batch-proof.vue";
import AdjustPayrollBatchTaxes from "@/modules/payroll/pages/payroll/batch/adjust-payroll-batch-taxes.vue";
import PayrollCheckBatchDetailsLayout from "@/modules/payroll/layout/PayrollCheckBatchDetailsLayout.vue";
import PayrollRegisterBatchDetailsLayout from "@/modules/payroll/layout/PayrollRegisterBatchDetailsLayout.vue";
import DirectDepositCodesList from "@/modules/payroll/pages/codes/direct-deposit-codes-list.vue";
import WorkersCompSummaryReport from "@/modules/payroll/pages/reports/workers-comp-summary-report.vue";
import W2MemoCodesList from "@/modules/payroll/pages/codes/w2-memo-codes-list.vue";
import PreviewCertifiedPayroll from "@/modules/payroll/pages/payroll/batch/preview-certified-payroll.vue";
import GeneralLiabilitySummaryReport from "@/modules/payroll/pages/reports/general-liability-summary-report.vue";
import EmployeeDirectDepositsReport from "@/modules/payroll/pages/reports/direct-deposits-report.vue"
import PayrollPositivePay from "@/modules/payroll/pages/payroll/batch/payroll-positive-pay.vue";
import PayrollHistoryDistributionReport from '@/modules/payroll/pages/reports/payroll-history-distribution-report.vue'
import DeductionCodesImport from "@/modules/payroll/pages/codes/deduction-codes-import.vue";
import FlexibleBenefitPlansImport from "@/modules/payroll/pages/codes/flexible-benefit-plans-import.vue";
import RetirementPlansImport from "@/modules/payroll/pages/codes/retirement-plans-import.vue";
import PayCodesImport from "@/modules/payroll/pages/codes/pay-codes-import.vue";
import UnionRatesImport from "@/modules/payroll/pages/rates/union-rates-import.vue";
import AdjustPayrollBatchEntries from "@/modules/payroll/pages/payroll/batch/adjust-payroll-batch-entries.vue";
import { RestifyResources } from "@/components/form/util";
import DocumentsTable from "@/modules/common/components/documents/DocumentsTable.vue";
import TimecardAdjustEntries from "@/modules/payroll/components/timecard/timecard-adjust-entries.vue";
import EmployeePayrolls from "@/modules/payroll/pages/employee/direct-deposits/employee-payrolls.vue";
import SelectedPayrollHistory from "@/modules/payroll/pages/reports/selected-payroll-history.vue";
import PayrollDirectDeposit from "@/modules/payroll/pages/payroll/batch/payroll-direct-deposit.vue";
import TimesheetsLayout from "@/modules/payroll/layout/TimesheetsLayout.vue";
import TimesheetsTable from "@/modules/payroll/pages/timesheets/TimesheetsTable.vue";
import TimesheetSettings from "@/modules/payroll/pages/settings/timesheet-settings.vue";
import EmployeeTimesheets from "@/modules/payroll/pages/timesheets/employee-timesheets.vue";
import TimesheetTasks from "@/modules/payroll/pages/timesheets/timesheet-tasks.vue";
import TimesheetScheduler from "@/modules/payroll/pages/timesheets/timesheet-scheduler.vue";
import WeeklyTimesheets from "@/modules/payroll/pages/timesheets/weekly-timesheets.vue";
import WorkerCompensationRatesImport from "@/modules/payroll/pages/rates/worker-compensation-rates-import.vue";
import GeneralLiabilityRatesImport from "@/modules/payroll/pages/rates/general-liability-rates-import.vue";
import TimeOffRateAdd from "@/modules/payroll/pages/rates/time-off-rate-add.vue";
import TimeOffRateEdit from "@/modules/payroll/pages/rates/time-off-rate-edit.vue";
import ToDateEarningsAndTaxesReport from "@/modules/payroll/pages/reports/to-date-earnings-and-taxes.vue";
import EmployeeW2SReport from "@/modules/payroll/pages/reports/employee-w2s-report.vue";
import AllAdjustments from "@/modules/payroll/pages/adjustment/all-adjustments.vue";
import EmployeeListingReport from "@/modules/payroll/pages/reports/employee-listing.vue";
import QuarterlyStateUnemploymentReport from "@/modules/payroll/pages/reports/quarterly-state-unemployment-report.vue";
import PayrollPaymentRegisterReport from "@/modules/payroll/pages/reports/payroll-payment-register-report.vue";
import TimesheetsList from "@/modules/payroll/pages/timesheets/timesheets-list.vue";
import TimesheetTaskVisibility from "@/modules/payroll/pages/timesheets/timesheet-task-visibility.vue";
import EmployeeW2s from "@/modules/payroll/pages/employee/employee-w2s.vue";
import LaborBurdenValues from "@/modules/payroll/pages/settings/labor-burden-values.vue";

export const reportRoutes = [
  {
    path: 'payroll-history-distribution',
    name: 'Payroll History Distribution',
    title: 'Payroll History Distribution',
    meta: {
      permissions: ['payroll_reports'],
      appScreen: '48.71',
    },
    component: PayrollHistoryDistributionReport,
  },
  {
    path: 'payroll-history-by-employee',
    name: 'Payroll History - By Employee',
    title: 'Payroll History - By Employee',
    meta: {
      permissions: ['payroll_reports'],
      appScreen: '48.72',
    },
    component: PayrollHistoryByEmployeeReport,
  },
  {
    path: 'selected-payroll-history',
    name: 'Selected Payroll History',
    title: 'Selected Payroll History',
    meta: {
      permissions: ['payroll_reports'],
      appScreen: '48.75',
    },
    component: SelectedPayrollHistory,
  },
  {
    path: 'general-liability-summary',
    name: 'General Liability Summary Report',
    title: 'General Liability Summary',
    meta: {
      permissions: ['payroll_reports'],
      appScreen: '48.77',
    },
    component: GeneralLiabilitySummaryReport,
  },
  {
    path: 'quarterly-state-unemployment',
    name: 'Quarterly State Unemployment Report',
    title: 'Quarterly State Unemployment',
    meta: {
      permissions: ['payroll_reports'],
      appScreen: '48.78',
    },
    component: QuarterlyStateUnemploymentReport
  },
  {
    path: 'payroll-payment-register',
    name: 'Payroll Payment Register Report',
    title: 'Payroll Payment Register',
    meta: {
      permissions: ['payroll_reports'],
      appScreen: '48.79',
    },
    component: PayrollPaymentRegisterReport,
  },
  {
    path: 'quarterly-earnings-federal',
    name: 'Quarterly Earnings - Federal',
    title: 'Quarterly Earnings - Federal',
    meta: {
      permissions: ['payroll_reports'],
      appScreen: '48.81',
    },
    component: QuarterlyEarningsFederalReport,
  },
  {
    path: 'quarterly-earnings-state-local',
    name: 'Quarterly Earnings - State / Local',
    title: 'Quarterly Earnings - State / Local',
    meta: {
      permissions: ['payroll_reports'],
      appScreen: '48.82',
    },
    component: QuarterlyEarningsStateLocalReport,
  },
  {
    path: 'to-date-earnings-and-taxes',
    name: 'To-Date Earnings & Taxes',
    title: 'To-Date Earnings & Taxes',
    meta: {
      permissions: ['payroll_reports'],
      appScreen: '48.83',
    },
    component: ToDateEarningsAndTaxesReport,
  },
  {
    path: 'employee-w2s',
    name: `Employee W2s Report`,
    title: `Employee W2s Report`,
    meta: {
      permissions: ['payroll_reports'],
      appScreen: '48.84',
    },
    component: EmployeeW2SReport,
  },
  {
    path: 'employee-listing',
    name: 'Employee Listing Report',
    title: 'Employee Listing Report',
    meta: {
      permissions: ['payroll_reports'],
      appScreen: '48.85',
    },
    component: EmployeeListingReport,
  },
  {
    path: 'certified-payroll',
    name: 'Certified Payroll Report',
    title: 'Certified Payroll Report',
    meta: {
      permissions: ['payroll_reports'],
      appScreen: '48.86',
    },
    component: CertifiedPayrollReport,
  },
  {
    path: 'union-report',
    name: 'Union Report',
    title: 'Union Report',
    meta: {
      permissions: ['payroll_reports'],
      appScreen: '48.87',
    },
    component: UnionReport,
  },
  {
    path: 'workers-comp-summary',
    name: `Worker's Comp Summary Report`,
    title: `Worker's Comp Summary Report`,
    meta: {
      permissions: ['payroll_reports'],
      appScreen: '48.88',
    },
    component: WorkersCompSummaryReport,
  },
  {
    path: 'employee-direct-deposits',
    name: 'Employee Direct Deposit Listing',
    title: 'Employee Direct Deposit Listing',
    meta: {
      permissions: ['payroll_reports'],
      appScreen: '41.16A',
    },
    component: EmployeeDirectDepositsReport,
  },
]
export default [
  {
    path: '/payroll',
    name: 'Payroll',
    redirect: '/payroll/employees',
    meta: {
      permissions: ['payroll_show'],
      productionReady: true,
      isModule: true,
    },
    component: EmptyLayout,
    children: [
      {
        path: '',
        name: 'Setup Employees',
        title: 'Setup Employees',
        description: 'Quickly access and manage your company employee data',
        redirect: '/payroll/employees',
        meta: {
          permissions: ['employees_show', 'payroll_show'],
          productionReady: true,
          hideBreadCrumb: true,
        },
        component: EmployeesMenuLayout,
        children: [
          {
            path: 'employees',
            name: 'Employees',
            title: 'Employees',
            description: 'Setup Employee Information',
            meta: {
              permissions: ['employees_show', 'payroll_show'],
              productionReady: true,
            },
            component: EmployeeList,
          },
          {
            path: 'employee-w2s',
            name: 'Employee W2s',
            title: 'Employee W2s',
            description: 'Send Employee W2 Forms via email',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: EmployeeW2s,
          },
          {
            path: 'employees/import',
            name: 'Import Employees',
            title: 'Import Employees',
            description: 'Import Employees from CSV or Excel files',
            meta: {
              permissions: ['employees_update'],
              productionReady: true,
            },
            component: EmployeeImport,
          },
          {
            path: 'employees/:id',
            name: 'Employee Details ',
            redirect: '/payroll/employees',
            meta: {
              permissions: ['employees_show', 'payroll_show'],
              productionReady: true,
            },
            component: EmployeeLayout,
            children: [
              {
                path: 'view',
                name: 'View Employee',
                meta: {
                  permissions: ['employees_show', 'payroll_show'],
                  productionReady: true,
                  resourceName: 'employees',
                },
                component: EmployeeDetails,
              },
              {
                path: 'edit',
                name: 'Edit Employee',
                meta: {
                  permissions: ['employees_update'],
                  productionReady: true,
                  resourceName: 'employees',
                },
                component: EmployeeEdit,
              },
              {
                path: 'documents',
                name: 'Employee Documents',
                meta: {
                  permissions: ['employee_documents_show'],
                  resourceName: RestifyResources.Employees,
                },
                component: DocumentsTable,
              },
              {
                path: 'timesheets',
                name: 'Employee Timesheets',
                meta: {
                  permissions: ['employees_show'],
                  productionReady: true,
                  resourceName: 'employees',
                },
                component: EmployeeTimesheets,
              },
              {
                path: 'checks',
                name: 'Employee Checks',
                meta: {
                  permissions: ['employees_show', 'payroll_show'],
                  productionReady: true,
                  resourceName: 'employees',
                },
                component: EmployeeChecksDesktop,
                props: route => ({ employeeId: route.params.id })
              },
              {
                path: 'w2-forms',
                name: 'Employee W2 Forms',
                meta: {
                  permissions: ['employees_show', 'payroll_show'],
                  productionReady: true,
                  resourceName: 'employees',
                },
                component: EmployeeW2Forms,
              },
              {
                path: 'taxes',
                redirect: '/payroll/employees/:id/taxes/state',
                name: 'Employee Taxes',
                meta: {
                  permissions: ['employees_update'],
                  resourceName: 'employees',
                },
                component: EmployeeTaxesLayout,
                children: [
                  {
                    path: 'state',
                    name: 'Employee State Taxes',
                    meta: {
                      permissions: ['employees_show', 'payroll_show'],
                      resourceName: 'employees',
                    },
                    component: StateTaxes,
                  },
                ],
              },
              {
                path: 'rates',
                redirect: '/payroll/employees/:id/rates/pay-rates',
                name: 'Employee Pay Rates ',
                meta: {
                  permissions: ['employees_update'],
                  resourceName: 'employees',
                },
                component: EmployeeRatesLayout,
                children: [
                  {
                    path: 'pay-rates',
                    name: 'Employee Pay Rates',
                    meta: {
                      permissions: ['employees_show', 'payroll_show'],
                      resourceName: 'employees',
                    },
                    component: EmployeePayRates,
                  },
                  {
                    path: 'benefit-rates',
                    name: 'Employee Benefit Rates',
                    meta: {
                      permissions: ['employees_show', 'payroll_show'],
                      resourceName: 'employees',
                    },
                    component: EmployeeBenefits,
                  },
                  {
                    path: 'flexible-benefit-rates',
                    name: 'Employee Flexible Benefit Rates',
                    meta: {
                      permissions: ['employees_show', 'payroll_show'],
                      resourceName: 'employees',
                    },
                    component: EmployeeFlexibleBenefitRates,
                  },
                  {
                    path: 'deduction-rates',
                    name: 'Employee Deduction Rates',
                    meta: {
                      permissions: ['employees_show', 'payroll_show'],
                      resourceName: 'employees',
                    },
                    component: EmployeeDeductionRates,
                  },
                  {
                    path: 'retirement-rates',
                    name: 'Employee Retirement Rates',
                    meta: {
                      permissions: ['employees_show', 'payroll_show'],
                      resourceName: 'employees',
                    },
                    component: EmployeeRetirementRates,
                  },
                ],
              },
              {
                path: 'time-off',
                name: 'Employee Time Off',
                meta: {
                  permissions: ['employees_show', 'payroll_show'],
                  resourceName: 'employees',
                },
                component: EmployeeTimeOff,
              },
              {
                path: 'direct-deposit',
                name: 'Employee Direct Deposit',
                meta: {
                  permissions: ['employees_show', 'payroll_show'],
                  resourceName: 'employees',
                  appScreen: '41.16',
                },
                component: EmployeeDirectDeposit,
              },
              {
                path: 'payrolls',
                name: 'Employee Payrolls',
                meta: {
                  permissions: ['employees_show', 'payroll_show'],
                },
                component: EmployeePayrolls,
              },
            ]
          },
          {
            path: 'pay-rates',
            name: 'All Pay Rates',
            title: 'Employee Pay Rates',
            description: 'Specify hourly pay rates as well as special pay for each employee.',
            meta: {
              permissions: ['employees_show', 'payroll_show'],
              appScreen: '41.12',
            },
            component: PayRates,
          },
          {
            path: 'pay-rates/add',
            name: 'Add Pay Rates',
            title: 'Add Pay Rates',
            meta: {
              permissions: ['employees_show', 'payroll_show'],
              appScreen: '41.12',
            },
            component: PayRatesAdd,
          },
          {
            path: 'pay-rates/:id/edit',
            name: 'Edit Pay Rates',
            meta: {
              permissions: ['employees_show'],
              appScreen: '41.12',
            },
            component: PayRatesEdit,
          },
          {
            path: 'benefit-rates',
            name: 'All Benefit Rates',
            title: 'Employee Benefit Rates',
            description: 'Setup employee benefits',
            meta: {
              permissions: ['employees_show', 'payroll_show'],
            },
            component: BenefitsList,
          },
          {
            path: 'benefit-rates/add',
            name: 'Add Benefit Rates',
            title: 'Add Benefit Rates',
            meta: {
              permissions: ['employees_show', 'payroll_show'],
            },
            component: BenefitsAdd,
          },
          {
            path: 'benefit-rates/:id/edit',
            name: 'Edit Benefit Rates',
            meta: {
              permissions: ['employees_show', 'payroll_show'],
            },
            component: BenefitsEdit,
          },

          {
            path: 'flexible-benefit-rates',
            name: 'All Flexible Benefit Rates',
            title: 'Flexible Flexible Employee Benefit Rates',
            description: 'Setup employee flexible benefits',
            meta: {
              permissions: ['employees_show', 'payroll_show'],
            },
            component: FlexibleBenefitRatesList,
          },
          {
            path: 'flexible-benefit-rates/add',
            name: 'Add Flexible Benefit Rates',
            title: 'Add Flexible Benefit Rates',
            meta: {
              permissions: ['employees_show', 'payroll_show'],
            },
            component: FlexibleBenefitRatesAdd,
          },
          {
            path: 'flexible-benefit-rates/:id/edit',
            name: 'Edit Flexible Benefit Rates',
            meta: {
              permissions: ['employees_show', 'payroll_show'],
            },
            component: FlexibleBenefitRatesEdit,
          },

          {
            path: 'deduction-rates',
            name: 'All Deduction Rates',
            title: 'Employee Deduction Rates',
            description: 'Specify hourly deduction rates as well as special deduction for each employee.',
            meta: {
              permissions: ['employees_show', 'payroll_show'],
              appScreen: '41.12',
            },
            component: DeductionRates,
          },
          {
            path: 'deduction-rates/add',
            name: 'Add Deduction Rates',
            title: 'Add Deduction Rates',
            meta: {
              permissions: ['employees_show', 'payroll_show'],
            },
            component: DeductionRatesAdd,
          },
          {
            path: 'deduction-rates/:id/edit',
            name: 'Edit Deduction Rates',
            title: 'Edit Deduction Rates',
            meta: {
              permissions: ['employees_show', 'payroll_show'],
              appScreen: '41.12',
            },
            component: DeductionRatesEdit,
          },

          {
            path: 'retirement-rates',
            name: 'All Retirement Rates',
            title: 'Employee Retirement Rates',
            meta: {
              permissions: ['employees_show', 'payroll_show'],
            },
            component: RetirementRates,
          },
          {
            path: 'retirement-rates/add',
            name: 'Add Retirement Rates',
            title: 'Add Retirement Rates',
            meta: {
              permissions: ['employees_show', 'payroll_show'],
            },
            component: RetirementRatesAdd,
          },
          {
            path: 'retirement-rates/:id/edit',
            name: 'Edit Retirement Rates',
            title: 'Edit Retirement Rates',
            meta: {
              permissions: ['employees_show', 'payroll_show'],
            },
            component: RetirementRatesEdit,
          },

          {
            path: 'taxes',
            name: 'All Employee Taxes',
            title: 'Employee Taxes',
            description: 'Establish the state tax withholding status and withholding allowances for each employee.',
            redirect: '/payroll/taxes/state',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: EmployeeTaxLayout,
            children: [
              {
                path: 'state',
                name: 'Employee State Tax Status',
                title: 'Employee State Tax Status',
                meta: {
                  permissions: ['payroll_settings'],
                  appScreen: '41.13',
                },
                component: StateTaxes,
              },
            ]
          },
          {
            path: 'adjustments',
            name: 'Year-To-Date Adjustments',
            title: 'Year-To-Date Adjustments',
            description: 'To review and/or adjust the year-to-date earnings, taxes, and/or deductions recorded for each employee.',
            redirect: '/payroll/adjustments/earnings',
            meta: {
              permissions: ['employees_show', 'payroll_show'],
              appScreen: '41.14',
            },
            component: AdjustmentLayout,
            children: [
              {
                path: 'earnings',
                name: 'Earnings Adjustment',
                title: 'Earnings Adjustment',
                meta: {
                  permissions: ['employees_show', 'payroll_show'],
                  appScreen: '41.14',
                },
                component: Earnings,
              },
              {
                path: 'benefits',
                name: 'Benefits Adjustment',
                title: 'Benefits Adjustment',
                meta: {
                  permissions: ['employees_show', 'payroll_show'],
                  appScreen: '41.14',
                },
                component: Benefits,
              },
              {
                path: 'taxes',
                name: 'Taxes Adjustment',
                title: 'Taxes Adjustment',
                meta: {
                  permissions: ['employees_show'],
                  appScreen: '41.14',
                },
                component: Taxes,
              },
              {
                path: 'deductions',
                name: 'Deductions Adjustment',
                title: 'Deductions Adjustment',
                meta: {
                  permissions: ['employees_show', 'payroll_show'],
                  appScreen: '41.14',
                },
                component: Deductions,
              },
              {
                path: 'w2-memos',
                name: 'W2 Memos Adjustment',
                title: 'W2 Memos Adjustment',
                meta: {
                  permissions: ['employees_show', 'payroll_show'],
                  appScreen: '41.14',
                },
                component: W2Memos,
              },
              {
                path: 'all',
                name: 'All YTDs Adjustment',
                title: 'All YTDs Adjustment',
                meta: {
                  permissions: ['employees_show', 'payroll_show'],
                  appScreen: '41.14',
                },
                component: AllAdjustments,
              },
              {
                path: ':type/:employee_id/edit',
                name: 'Review Adjustments',
                title: 'Review Adjustments',
                meta: {
                  permissions: ['employees_show'],
                  appScreen: '41.14',
                },
                component: ReviewAdjustments,
              },
              {
                path: 'add',
                name: 'Add Review Adjustments',
                title: 'Review Adjustments',
                meta: {
                  permissions: ['employees_show', 'payroll_show'],
                  appScreen: '41.14',
                },
                component: AddAdjustments,
              },
            ]
          },
          {
            path: 'direct-deposits',
            name: 'Direct Deposits',
            title: 'Direct Deposits',
            description: 'Setup Direct Deposit information for applicable employees who request paychecks to be directly deposited to their checking and/or savings accounts.',
            meta: {
              permissions: ['employees_show', 'payroll_show'],
              resourceName: 'employees',
              appScreen: '41.16',
            },
            component: DirectDepositsList,
          },
          {
            path: 'direct-deposits/add',
            name: 'Add Employee Direct Deposit',
            title: 'Add Employee Direct Deposit',
            meta: {
              permissions: ['employees_show'],
              resourceName: 'employees',
              appScreen: '41.16',
            },
            component: DirectDepositsAdd,
          },
          {
            path: 'direct-deposits/:id/edit',
            name: 'Edit Employee Direct Deposit',
            meta: {
              permissions: ['employees_show', 'payroll_show'],
              resourceName: 'employees',
              appScreen: '41.16',
            },
            component: DirectDepositsEdit,
          },
        ]
      },
      {
        path: 'w2-forms-batches',
        name: 'W2 Forms Batches',
        title: 'W2 Forms Batches',
        description: 'Access, send or download Employee W2 Forms via email',
        meta: {
          permissions: ['employee_w2_forms_show', 'payroll_show'],
          productionReady: true,
        },
        component: W2FormsBatchesList,
      },
      {
        path: 'w2-forms-batches/:year',
        name: 'W2 Forms',
        meta: {
          permissions: ['employee_w2_forms_show', 'payroll_show'],
          productionReady: true,
        },
        component: W2Forms,
      },
      {
        path: 'check-batches',
        name: 'Payroll Check Batches',
        title: 'Payroll Check Batches',
        description: 'Access, send or download Payroll Check Batches via email',
        meta: {
          permissions: ['employee_checks_show', 'payroll_show'],
          productionReady: true,
        },
        component: CheckBatches,
      },
      {
        path: 'check-batches/:id',
        name: 'Check Batch Details ',
        meta: {
          permissions: ['employee_checks_show', 'payroll_show'],
          productionReady: true,
        },
        component: EmployeeChecksDesktop,
      },
      {
        path: 'codes',
        name: 'Codes',
        title: 'Setup Payroll Codes',
        description: 'Setup Payroll Codes',
        meta: {
          permissions: ['payroll_settings'],
        },
        redirect: '/payroll/codes/craft-codes',
        component: CodesLayout,
        children: [
          {
            path: 'craft-codes',
            name: 'Craft Codes',
            title: 'Craft Codes',
            description: 'Manage Craft Codes',
            meta: {
              permissions: ['payroll_settings'],
              appScreen: 'Setup Craft Codes: 42.21',
            },
            component: CraftCodesList,
          },
          {
            path: 'craft-codes/import',
            name: 'Import Craft Codes',
            title: 'Import Craft Codes',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: CraftCodesImport,
          },
          {
            path: 'pay-codes',
            name: 'Pay Codes',
            title: 'Pay Codes',
            description: 'To define pay codes used in payroll calculations',
            meta: {
              permissions: ['payroll_settings'],
              appScreen: 'Manage Pay Codes: 42.22',
            },
            component: PayCodesList,
          },
          {
            path: 'pay-codes/import',
            name: 'Import Pay Codes',
            title: 'Import Pay Codes',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: PayCodesImport,
          },
          {
            path: 'benefit-codes',
            name: 'Benefit Codes',
            title: 'Benefit Codes',
            description: 'To define benefit codes used in payroll calculations',
            meta: {
              permissions: ['payroll_settings'],
              appScreen: 'Manage Benefit Codes: 42.22',
            },
            component: BenefitCodesList,
          },
          {
            path: 'benefit-codes/import',
            name: 'Import Benefit Codes',
            title: 'Import Benefit Codes',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: BenefitCodesImport,
          },
          {
            path: 'flexible-benefit-plans',
            name: 'Flexible Benefit Plans',
            title: 'Flexible Benefit Plans',
            description: 'To define flexible benefit plans used in payroll calculations',
            meta: {
              permissions: ['payroll_settings'],
              appScreen: 'Manage Deduction Codes: 42.23',
            },
            component: FlexibleBenefitPlansList,
          },
          {
            path: 'flexible-benefit-plans/import',
            name: 'Import Flexible Benefits',
            title: 'Import Flexible Benefits',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: FlexibleBenefitPlansImport,
          },
          {
            path: 'deduction-codes',
            name: 'Deduction Codes',
            title: 'Deduction Codes',
            description: 'To define deduction codes used in payroll calculations',
            meta: {
              permissions: ['payroll_settings'],
              appScreen: 'Manage Deduction Codes: 42.23',
            },
            component: DeductionCodesList,
          },
          {
            path: 'deduction-codes/import',
            name: 'Import Deduction Codes',
            title: 'Import Deduction Codes',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: DeductionCodesImport,
          },
          {
            path: 'direct-deposit-codes',
            name: 'Direct Deposit Codes',
            title: 'Direct Deposit Codes',
            description: 'To define codes used as Bank Direct Deposit deduction',
            meta: {
              permissions: ['payroll_settings'],
              appScreen: 'Manage Deduction Codes: 42.23',
            },
            component: DirectDepositCodesList,
          },
          {
            path: 'retirement-plans',
            name: 'Retirement Plans',
            title: 'Retirement Plans',
            description: 'To define retirement plans used in payroll calculations',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: RetirementPlansList,
          },
          {
            path: 'retirement-plans/import',
            name: 'Import Retirement Plans',
            title: 'Import Retirement Plans',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: RetirementPlansImport,
          },
          {
            path: 'time-off',
            name: 'Time Off',
            title: 'Time Off',
            description: 'Define time off policies and accruals',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: TimeOffList,
          },
          {
            path: 'time-off/add',
            name: 'Create Time Off Policy',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: TimeOffCreate,
          },
          {
            path: 'time-off/:id',
            name: 'Time Off Details',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: TimeOffPolicyLayout,
            children: [
              {
                path: 'view',
                name: 'Time Off Policy Details',
                component: TimeOffDetails,
              },
              {
                path: 'edit',
                name: 'Time Off Policy Edit',
                component: TimeOffEdit,
              }
            ]
          },
          {
            path: 'tax-codes',
            name: 'Tax Codes',
            title: 'Tax Codes',
            description: 'Tax codes list used in payroll calculations',
            meta: {
              permissions: ['payroll_settings'],
              appScreen: 'Manage Deduction Codes: 42.23',
            },
            component: TaxCodesList,
          },
          {
            path: 'ocip-codes',
            name: 'OCIP Codes',
            title: 'OCIP Codes',
            description: 'Setup OCIP Code Descriptions',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: OcipCodesList,
          },
          {
            path: 'ocip-codes/import',
            name: 'Import OCIP Codes',
            title: 'Import OCIP Codes',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: OcipCodesImport,
          },
          {
            path: 'department-codes',
            name: 'Department Codes',
            title: 'Department Codes',
            description: 'Setup Department Code Descriptions',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: DepartmentCodes,
          },
          {
            path: 'w2-memo-codes',
            name: 'W2 Memo Codes',
            title: 'W2 Memo Codes',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: W2MemoCodesList,
          },
        ],
      },
      {
        path: 'rates',
        name: 'Rates',
        title: 'Setup Payroll Rates',
        description: 'Setup Payroll Rates',
        meta: {
          permissions: ['payroll_settings'],
        },
        redirect: '/payroll/rates/job-rates',
        component: RatesLayout,
        children: [
          {
            path: 'job-rates',
            name: 'Job Rates',
            title: 'Job Rates',
            description: 'This optional payroll table provides the capability to automatically assign pay/deduction rates, or working union codes based on the job where the employee is working.',
            meta: {
              permissions: ['payroll_settings'],
              appScreen: 'Manage Job Rates: 42.25',
            },
            component: JobRatesList,
          },
          {
            path: 'job-rates/add',
            name: 'Add Job Rates',
            title: 'Add Job Rates',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: JobRateAdd,
          },
          {
            path: 'job-rates/:id/edit',
            name: 'Edit Job Rates',
            title: 'Edit Job Rates',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: JobRateEdit,
          },
          {
            path: 'time-off-rates',
            name: 'Time Off Rates',
            title: 'Time Off Rates',
            description: 'Define time off rates for each time off policy.',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: TimeOffRatesList,
          },
          {
            path: 'time-off-rates/add',
            name: 'Add Time Off Rates',
            title: 'Add Time Off Rates',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: TimeOffRateAdd,
          },
          {
            path: 'time-off-rates/:id/edit',
            name: 'Edit Time Off Rates',
            title: 'Edit Time Off Rates',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: TimeOffRateEdit,
          },
          {
            path: 'unions',
            name: 'Unions',
            title: 'Unions',
            description: 'Manage Unions',
            meta: {
              permissions: ['payroll_settings'],
              appScreen: 'Manage Union Rates: 42.24',
            },
            component: UnionsList,
          },
          {
            path: 'unions/import',
            name: 'Import Union Rates',
            title: 'Import Union Rates',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: UnionRatesImport,
          },
          {
            path: 'unions/add',
            name: 'Add Union',
            title: 'Add Union',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: UnionAdd,
          },
          {
            path: 'unions/:id/edit',
            name: 'Edit Union',
            title: 'Edit Union',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: UnionEdit,
          },
          {
            path: 'general-liability-rates',
            name: 'General Liability Rates',
            title: 'General Liability Rates',
            description: 'Manage General Liability Rates',
            meta: {
              permissions: ['payroll_settings'],
              appScreen: 'Manage General Liability Rates: 42.27',
            },
            component: GeneralLiabilityRatesList,
          },
          {
            path: 'general-liability-rates/import',
            name: 'Import General Liability Rates',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: GeneralLiabilityRatesImport,
          },
          {
            path: 'worker-compensation-rates',
            name: 'Worker Compensation Rates',
            title: 'Worker Compensation Rates',
            description: 'Manage Worker Compensation Rates',
            meta: {
              permissions: ['payroll_settings'],
              appScreen: 'Manage Worker Compensation Rates: 42.26',
            },
            component: WorkerCompensationRatesList,
          },
          {
            path: 'worker-compensation-rates/import',
            name: 'Import Worker Compensation Rates',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: WorkerCompensationRatesImport,
          },
          {
            path: 'copy-delete-rates',
            name: 'Copy/Delete Rates',
            title: 'Copy/Delete Rates',
            description: 'Copy-Delete Job-Union Rates',
            meta: {
              permissions: ['payroll_settings'],
              appScreen: 'Copy-Delete Job-Union Rates: 42.29',
            },
            component: CopyDeleteRates,
          },
        ],
      },
      {
        path: 'timesheets',
        name: 'Timesheets Layout',
        redirect: '/payroll/timesheets/list',
        description: 'View and manage timesheets entered in the timecard app',
        meta: {
          permissions: ['timesheets_approve'],
        },
        component: TimesheetsLayout,
        children: [
          {
            path: 'list',
            name: 'Timesheets',
            title: 'Timesheets',
            meta: {
              permissions: ['timesheets_approve'],
            },
            component: TimesheetsList,
          },
          {
            path: 'review',
            name: 'Review Timesheets',
            title: 'Review Timesheets',
            meta: {
              permissions: ['timesheets_approve'],
            },
            component: WeeklyTimesheets,
          },
          {
            path: 'tasks',
            name: 'Tasks',
            title: 'Tasks',
            meta: {
              permissions: ['tasks_store'],
            },
            component: TimesheetTasks,
          },
          {
            path: 'task-visibility',
            name: 'Task Visibility',
            title: 'Task Visibility',
            meta: {
              permissions: ['tasks_store'],
            },
            component: TimesheetTaskVisibility,
          },
          {
            path: 'calendar',
            name: 'Calendar',
            title: 'Calendar',
            meta: {
              permissions: ['tasks_store'],
            },
            component: TimesheetScheduler,
          },
        ]
      },
      {
        path: 'timecard-batches',
        name: 'Timecard Batches',
        title: 'Timecard Batches',
        redirect: '/payroll/timecard-batches/pending',
        description: 'Enter Employees` payroll hours and generate the salaried payroll for the current payroll period.',
        meta: {
          permissions: ['timecards_show', 'payroll_show'],
        },
        component: TimecardBatchesLayout,
        children: [
          {
            path: 'import',
            name: 'Import Timecard Batches',
            title: 'Import timecards',
            description: 'Import timecards from CSV or Excel files into the system.',
            meta: {
              permissions: ['timecards_store'],
            },
            component: TimecardImport,
          },
          {
            path: 'pending',
            name: 'Pending Timecard Batches',
            meta: {
              permissions: ['timecards_store'],
            },
            component: PendingTimecardBatches,
          },
          {
            path: 'computed',
            name: 'Computed Timecard Batches',
            meta: {
              permissions: ['timecards_store'],
            },
            component: ComputedTimecardBatches,
          },
          {
            path: 'posted',
            name: 'Posted Timecard Batches',
            meta: {
              permissions: ['timecards_store'],
            },
            component: PostedTimecardBatches,
          },
          {
            path: 'voided',
            name: 'Voided Timecard Batches',
            meta: {
              permissions: ['timecards_store'],
            },
            component: VoidedTimecardBatches,
          },
          {
            path: 'all',
            name: 'All Timecard Batches',
            meta: {
              permissions: ['timecards_store'],
            },
            component: AllTimecardBatches,
          },
          {
            path: 'add',
            name: 'Enter Timecards',
            title: 'Enter Timecards',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: timecardBatchAdd,
          },
          {
            path: ':id',
            name: 'Timecard Batch Details',
            meta: {
              permissions: ['timecards_show', 'payroll_show'],
            },
            redirect: '/payroll/timecard-batches/:id/view',
            component: TimecardBatchLayout,
            children: [
              {
                path: 'add',
                name: 'Enter Timecards for existing batch',
                meta: {
                  permissions: ['timecards_store'],
                },
                component: TimecardBatchAdd,
              },
              {
                path: 'view',
                name: 'Timecard Batch Summary',
                meta: {
                  permissions: ['timecards_show', 'payroll_show'],
                },
                component: TimecardBatchView,
              },
              {
                path: 'proof-listing',
                name: 'Timecard Batch Proof Listing ',
                meta: {
                  permissions: ['timecards_show', 'payroll_show'],
                },
                component: TimecardBatchProof,
              },
              {
                path: 'sources',
                name: 'Timecard Batch List By Source',
                meta: {
                  permissions: ['timecards_show', 'payroll_show'],
                },
                component: TimecardBatchBySource,
              },
              {
                path: 'adjust-entries',
                name: 'Timecard Batch Adjust Entries ',
                meta: {
                  permissions: ['timecards_show', 'payroll_show'],
                },
                component: TimecardAdjustEntries,
              },
              {
                path: 'timecards/:timecardId/edit',
                meta: {
                  permissions: ['timecards_update'],
                },
                component: TimecardEdit,
              },
              {
                path: 'timecards/:timecardId/details',
                meta: {
                  permissions: ['timecards_show'],
                },
                component: TimecardEdit,
              },
            ]
          },
        ]
      },
      {
        path: 'batches',
        title: 'Payroll Registers',
        name: 'Payroll Registers',
        redirect: '/payroll/batches/computed',
        meta: {
          permissions: ['payroll_show'],
        },
        component: PayrollBatchesLayout,
        children: [
          {
            path: 'computed',
            name: 'Computed payroll registers',
            meta: {
              permissions: ['payroll_show'],
            },
            component: ComputedPayrollBatches,
          },
          {
            path: 'posted',
            name: 'Posted payroll registers',
            meta: {
              permissions: ['payroll_show', 'payroll_show'],
            },
            component: PostedPayrollBatches,
          },
          {
            path: 'voided',
            name: 'Voided payroll registers',
            meta: {
              permissions: ['payroll_show', 'payroll_show'],
            },
            component: VoidedPayrollBatches,
          },
          {
            path: 'all',
            name: 'All payroll registers',
            meta: {
              permissions: ['payroll_show', 'payroll_show'],
            },
            component: AllPayrollBatches,
          },
          {
            name: 'Payroll Batch Details',
            path: ':id',
            redirect: '/payroll/batches/:id/summary',
            component: PayrollRegisterBatchDetailsLayout,
            children: [
              {
                path: 'summary',
                name: 'Payroll Register',
                meta: {
                  permissions: ['payroll_settings'],
                },
                component: PayrollBatchView,
              },
              {
                path: 'adjust-taxes',
                name: 'Adjust Payroll Register Taxes',
                meta: {
                  permissions: ['payroll_settings'],
                },
                component: AdjustPayrollBatchTaxes,
              },
              {
                path: 'adjust-entries',
                name: 'Adjust Payroll Register Entries',
                meta: {
                  permissions: ['payroll_settings'],
                },
                component: AdjustPayrollBatchEntries,
              },
              {
                path: 'positive-pay',
                name: 'Payroll Positive Pay',
                meta: {
                  permissions: ['payroll_settings'],
                },
                component: PayrollPositivePay,
              },
              {
                path: 'direct-deposit',
                name: 'Payroll Direct Deposit',
                meta: {
                  permissions: ['payroll_settings'],
                },
                component: PayrollDirectDeposit,
              },
              {
                path: 'preview-certified-payroll',
                name: 'Preview Certified Payroll',
                meta: {
                  permissions: ['payroll_settings'],
                },
                component: PreviewCertifiedPayroll,
              },
            ]
          }
        ]
      },
      {
        path: 'checks',
        name: 'Payroll Check Batches ',
        title: 'Payroll Check Batches',
        meta: {
          permissions: ['payroll_show'],
        },
        component: EmptyLayout,
        redirect: '/payroll/checks/batches',
        children: [
          {
            title: 'Payroll Check Batches List',
            path: 'batches',
            component: PayrollCheckBatches,
          },
          {

            path: 'batches/:id',
            name: 'Check Batch',
            meta: {
              permissions: ['payroll_show'],
            },
            component: PayrollCheckBatchDetailsLayout,
            children: [
              {
                path: 'view',
                name: 'Check Batch Details',
                title: 'Check Batch Details',
                component: PayrollCheckBatchDetails
              }
            ]
          }
        ]
      },
      {
        path: 'journals',
        name: 'PR Journals',
        redirect: 'journals',
        meta: {
          permissions: ['accounts_payable_reports'],
          appScreen: 'General Journal Entries: 10.40',
          module: 'accounts-payable',
        },
        component: EmptyLayout,
        children: [
          {
            path: '',
            name: 'PR Journals ',
            title: 'PR Journals',
            meta: {
              permissions: ['payroll_reports'],
              appScreen: 'General Journal Entries: 10.40',
              module: 'payroll',
              hideBreadCrumb: true,
            },
            component: PayrollJournals,
          },
          {
            path: ':id/view',
            name: 'Payroll Journal Details',
            meta: {
              permissions: ['payroll_reports'],
              appScreen: 'General Journal Entries: 10.40',
              module: 'payroll',
            },
            component: PayrollJournalDetails,
          },
        ],
      },
      {
        path: 'reports',
        name: 'PR Reports',
        redirect: '/payroll/reports/payroll-history-distribution',
        meta: {
          permissions: ['payroll_reports'],
        },
        component: Reports,
        children: reportRoutes,
      },
      {
        path: 'settings',
        name: 'Payroll Settings',
        redirect: '/payroll/settings/payroll-settings',
        meta: {
          permissions: ['payroll_settings'],
          appScreen: '49.97',
          hideBreadCrumb: true,
        },
        component: SettingsLayout,
        children: [
          {
            path: 'payroll-settings',
            name: 'Payroll Settings ',
            title: 'Payroll Settings ',
            description: 'Initialize required information on each state or local tax jurisdiction used by the Payroll system.',
            meta: {
              hideBreadCrumb: true,
              permissions: ['payroll_settings'],
              appScreen: 'Payroll Special Tasks: 49.91 ',
              module: 'payroll',
            },
            component: PayrollSettings,
          },
          {
            path: 'timesheet-settings',
            name: 'Timesheet Settings ',
            title: 'Timesheet Settings ',
            description: 'Settings used by timesheets and the timecard app.',
            meta: {
              hideBreadCrumb: true,
              permissions: ['timesheets_approve'],
            },
            component: TimesheetSettings,
          },
          {
            path: 'labor-burden-values',
            name: 'Labor Burden Values',
            title: 'Labor Burden Values',
            description: 'Establish the procedures for calculating and posting job labor burden.',
            meta: {
              hideBreadCrumb: true,
              permissions: ['payroll_settings'],
              appScreen: 'Payroll Labor Burden Values: 49.95 ',
            },
            component: LaborBurdenValues,
          },
          {
            path: 'activity-log',
            name: 'PR Settings Update Activity',
            meta: {
              permissions: ['payroll_settings'],
              module: $modules.PR,
            },
            component: SettingsActivityLog,
          },
          {
            path: 'state-taxes',
            name: 'State Tax Jurisdictions',
            title: 'State Tax Jurisdictions',
            description: 'Manage State Tax Jurisdictions',
            meta: {
              permissions: ['payroll_settings'],
              appScreen: 'Setup State Taxes: 49.97',
            },
            component: StateTaxJurisdictionsList,
          },
          {
            path: 'local-taxes',
            name: 'Local Tax Jurisdictions',
            title: 'Local Tax Jurisdictions',
            description: 'Manage Local Tax Jurisdictions',
            meta: {
              permissions: ['payroll_settings'],
              appScreen: 'Setup State Taxes: 49.97',
            },
            component: LocalTaxJurisdictionsList,
          },
          {
            path: 'open-calendar-year',
            name: 'Open Calendar Year',
            title: 'Open Calendar Year',
            meta: {
              permissions: ['payroll_settings'],
            },
            component: OpenCalendarYear,
          }
        ],
      }
    ],
  },
]
